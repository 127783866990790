import Vue from 'vue';
import Swiper from 'swiper';

Vue.component('block-slider', {

    data() {
        return {
            swiper: null,
        };
    },

    mounted() {
        this.initSwiper();
        this.intersect();
    },

    methods: {

        initSwiper() {
            const slider = this.$el.querySelector('.block-slider__slider');

            if (!slider) {
                return;
            }

            this.swiper = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                centeredSlides: true,
                observer: true,
                observeParents: true,
                pagination: {
                    el: this.$el.querySelector('.swiper-pagination'),
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><span></span></span>';
                    }
                },
                navigation: {
                    nextEl: '.page-slider-next',
                    prevEl: '.page-slider-prev',
                }
            });

            this.swiper.on('transitionStart', ()=> {
                const allVideos = this.$el.querySelectorAll('.swiper-slide video');
                if (!allVideos.length > 0) {
                    return;
                }

                allVideos.forEach(element => {
                    element.pause();             
                    element.currentTime = 0;
                });

            });

            this.swiper.on('transitionEnd', () => {
                if (this.$el.querySelectorAll('.swiper-slide-active video').length > 0) {
                    this.$el.querySelectorAll('.swiper-slide-active video')[0].currentTime = 0;
                    this.$el.querySelectorAll('.swiper-slide-active video')[0].play();
                }
                
            });
        },

        intersect() {
            var options = {
                rootMargin: "300px",
                threshold: 0
            };
            var videoSlider = this.$el.querySelector('.block-slider__slider');
            var observer = new IntersectionObserver((entries, observer) => {
                var isIntersecting =
                    typeof entries[0].isIntersecting === "boolean"
                        ? entries[0].isIntersecting
                        : entries[0].intersectionRatio > 0;
                if (isIntersecting) {
                    this.autoplayVideo();
                    observer.unobserve(videoSlider);
                }
            }, options);
            observer.observe(videoSlider);
        },

        autoplayVideo() {
            const firstVideo = this.$el.querySelector('.swiper-slide-active video');
            if (firstVideo) {
                firstVideo.play();
            }

            const allVideos = this.$el.querySelectorAll('.swiper-slide video');
            allVideos.forEach(element => {   
                element.addEventListener("ended", this.nextSlide);             
            });
        },

        nextSlide() {
            this.swiper.slideNext()
        },

        destroySwiper() {
            this.swiper.destroy();
            this.swiper = null;
        }

    },
});
