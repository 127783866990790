import Vue from 'vue';
import Axios from 'axios';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import Errors from '../classes/errors';

Vue.component('career-component', {

    props: {
        siteHandle: {
            type: String
        },
        jobs: {
            type: Array
        }
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            countryHandle: this.siteHandle,
            formCompleted: false,
            submitting: false,
            ready: false,
            privacyCheckBoxValue: false,
            privacyClicked: false,
        };
    },

    computed: {
        // a computed getter
        orderedJobs: function () {
          // `this` points to the vm instance

            var orderedJobs = _groupBy(this.jobs, 'country');

            for (const key in orderedJobs) {
                if (orderedJobs.hasOwnProperty(key)) {
                    var element = orderedJobs[key];
                    element = _sortBy(element, ['title']);

                    orderedJobs[key] = element
                }
            }
        
          return orderedJobs;
        }
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.ready = true;
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        onSubmit(event) {
            const form = event.currentTarget;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            Axios
                .post("/", new FormData(form))
                .then(response => {
                    const result = response.data;

                    if (result.data.errors) {
                        this.errors.clear();

                        this.errors.record(result.data.errors);
                        this.submitting = false;
                        this.scrollToFirstError();
                        return;
                    }

                    // Success
                    this.formCompleted = true;
                    this.errors.clear();
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error.response);
                });
        },

        scrollToFirstError() {
            Vue.nextTick(() => {
                let firstError = document.querySelector(".form-label--error");
                zenscroll.center(firstError);
            });
        },

        scrollToTop() {
            Vue.nextTick(() => {
                zenscroll.toY(0);
            });
        },

    },
});
