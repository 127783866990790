import Vue from 'vue';
import { parse } from 'date-fns';
import Errors from '../classes/errors';

Vue.component('countdown-component', {
    props: [
        'deadline'
    ],
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            total: null,
            seconds: 0,
            minutes: 0,
            hours: 0,
            days: 0,
            deadlineReached: false,
            parsedDeadline: ''
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        var result = parse(
            this.deadline,
            'yyyy-MM-dd hh:mm:ss',
            new Date()
        )
        this.parsedDeadline = result;
        this.remainder = this.remainingTime(this.deadline);
        this.updateCountdown();
        this.timeinterval = setInterval(this.updateCountdown, 1000);
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        remainingTime(deadline) {
            this.total = Date.parse(deadline) - Date.parse(new Date());

            if (this.total <= 0) {
                EventBus.$emit('deadline-has-expired', true);
                this.deadlineReached = true;
                return;
            }

            this.seconds = ('0' + Math.floor( (this.total / 1000) % 60 )).slice(-2);
            this.minutes = ('0' + Math.floor( (this.total / 1000 / 60) % 60 )).slice(-2);
            this.hours = ('0' + Math.floor( (this.total / (1000 * 60 * 60)) % 24 )).slice(-2);
            this.days = ('0' + Math.floor( this.total / (1000 * 60 * 60 * 24) )).slice(-2);
        },

        updateCountdown() {
            this.remainingTime(this.parsedDeadline);
        },
    },

    beforeDestroy() {
        clearInterval(this.timeinterval);
    }
});
