import Vue from 'vue';
import Errors from '../classes/errors';

import { gsap } from 'gsap';
import Swiper from 'swiper';

Vue.component('split-image-slider-component', {
    props: [
        'slides'
    ],
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            slidesObj: null,
            leftSlides: [],
            rightSlides: [],
            left: null,
            right: null,
            isMobile: window.innerWidth < 768,
            slideIntervalMobile: null,
            slideIntervalLeft: null,
            slideIntervalRight: null,
            slideInterval: 5000
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        // window.addEventListener('resize', debounce(this.resizeEvent, 250));

        // this.slidesObj = JSON.parse(this.slides);

        // this.leftSlides = this.slidesObj.filter((item) => {
        //     if (item.orientation.value === 'left') {
        //         return item;
        //     }
        //     this.rightSlides.push(item);
        // });

        // this.leftSlides.reverse();

        this.swiper = new Swiper('.swiper-container--left', {
            direction: 'vertical',
            speed: 1200,
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 5000
            }
        });

        this.swiper2 = new Swiper('.swiper-container--right', {
            direction: 'vertical',
            speed: 1200,
            loop: true,
            allowTouchMove: false,
            initialSlide: 4,
            autoplay: {
                delay: 5000,
                reverseDirection: true
            }
        });
        
        document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        handleVisibilityChange() {
            if (document.hidden) {
                this.swiper.autoplay.stop();
                this.swiper2.autoplay.stop();
            } else  {
                this.swiper.autoplay.start();
                this.swiper2.autoplay.start();
            }
        },

        setupSliders() {
            this.left = {
                wrapper: this.$el.querySelector('.split-image-slider__half--left'),
                container: this.$el.querySelector('.split-image-slider__half--left').querySelector('.split-image-slider__container'),
                slides: this.$el.querySelector('.split-image-slider__half--left').querySelectorAll('.split-image-slider__slide'),
                slidesCount:this.$el.querySelector('.split-image-slider__half--left').querySelectorAll('.split-image-slider__slide').length,
                slidesIndex:this.$el.querySelector('.split-image-slider__half--left').querySelectorAll('.split-image-slider__slide').length - 1,
                startPos: 0,
                currentSlide: 0
            }

            this.right = {
                wrapper: this.$el.querySelector('.split-image-slider__half--right'),
                container: this.$el.querySelector('.split-image-slider__half--right').querySelector('.split-image-slider__container'),
                slides: this.$el.querySelector('.split-image-slider__half--right').querySelectorAll('.split-image-slider__slide'),
                slidesCount: this.$el.querySelector('.split-image-slider__half--right').querySelectorAll('.split-image-slider__slide').length,
                slidesIndex: this.$el.querySelector('.split-image-slider__half--right').querySelectorAll('.split-image-slider__slide').length - 1,
                startPos: -(this.$el.querySelector('.split-image-slider__half--right').querySelectorAll('.split-image-slider__slide').length * 2 * 100) - 100,
                currentSlide: 0
            }

            console.log(this.right.startPos);

            // this.mobileSlide = {
            //     wrapper: this.$el.querySelector('.split-image-slider__half--mobile'),
            //     container: this.$el.querySelector('.split-image-slider__half--mobile').querySelector('.split-image-slider__container'),
            //     slides: this.$el.querySelector('.split-image-slider__half--mobile').querySelectorAll('.split-image-slider__slide'),
            //     slidesCount: 0,
            //     slidesIndex: 0,
            //     startPos: 0,
            //     currentSlide: 0,
            //     currentPosition: 0
            // }

            // Set right container
            gsap.set(this.right.wrapper, {
                yPercent: this.right.startPos
            });

            // Make clone for desktop slider
            const cloneLeft = this.left.container.cloneNode(true);
            this.left.wrapper.append(cloneLeft);
            const cloneRight = this.right.container.cloneNode(true);
            this.right.wrapper.append(cloneRight);

            // Check what function needs to run based on screenwidth

            this.slideIntervalLeft = setInterval(this.moveSlides.bind(this, 'left'), this.slideInterval);
            this.slideIntervalRight = setInterval(this.moveSlides.bind(this, 'right'), this.slideInterval);
        },

        moveSlides(side) {
            const tl = gsap.timeline();

            if (this[side].currentSlide === this[side].slidesIndex) {
                setTimeout(() => {
                    gsap.set(this[side].wrapper, {
                        y: 0
                    });
                    this[side].currentSlide = 0;
                }, 2000);
            }

            tl
                .fromTo(this[side].wrapper, 1, {
                    yPercent: side === 'right' ? this[side].startPos + (this[side].currentSlide * 100) : this.left.currentSlide * -100
                }, {
                    yPercent: side === 'right' ? this[side].startPos + (this[side].currentSlide * 100) + 100 : (this.left.currentSlide * -100) - 100
                });

                this[side].currentSlide++;
        },

        moveSlidesMobile() {
            const tl = gsap.timeline();

            if (this.mobileSlide.currentSlide === (this.mobileSlide.slides.length / 2) - 1) {
                setTimeout(() => {
                    gsap.set(this.mobileSlide.wrapper, {
                        x: 0
                    });
                    this.mobileSlide.currentSlide = 0;
                    this.mobileSlide.currentPosition = 0;
                }, 1000);
            }

            tl
                .fromTo(this.mobileSlide.wrapper, 1, {
                    x: this.mobileSlide.currentPosition
                }, {
                    x: () => {
                        const newPos = this.mobileSlide.currentPosition - this.mobileSlide.slides[this.mobileSlide.currentSlide].offsetWidth - 2;
                        this.mobileSlide.currentPosition = newPos;
                        return newPos;
                    }
                });

                this.mobileSlide.currentSlide++;
        },

        resizeEvent() {
            // Clear all
            clearInterval(this.slideIntervalLeft);
            clearInterval(this.slideIntervalRight);
            clearInterval(this.slideIntervalMobile);

            // if (window.innerWidth < 768) {
            //     this.isMobile = true;
            //     gsap.set(this.mobileSlide.wrapper, {
            //         x: 0
            //     });
            //     this.mobileSlide.currentSlide = 0;
            //     this.mobileSlide.currentPosition = 0;
            //     this.slideIntervalMobile = setInterval(this.moveSlidesMobile, 10000);
            //     return;
            // }

            this.isMobile = false;
            this.slideIntervalLeft = setInterval(this.moveSlides.bind(this, 'left'), this.slideInterval);
            this.slideIntervalRight = setInterval(this.moveSlides.bind(this, 'right'), this.slideInterval);
        }
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.resizeEvent);
        clearInterval(this.slideIntervalLeft);
        clearInterval(this.slideIntervalRight);
        clearInterval(this.slideIntervalMobile);
    }
});
