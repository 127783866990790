import Vue from 'vue';

Vue.component('video-player', {
    props: {
        isActive: {
            value: Boolean
        },
        hasLoop: {
            value: Boolean,
            default: false
        }
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            video: null,
            videoPlaying: false,
        }
    },

    mounted() {
        // Vue.nextTick(this.setupVideo);
        this.intersect();
    },

    watch: {
        isActive: function (newVal, oldVal) {
            if (newVal == false) {
                this.video.pause();
                this.videoPlaying = false
            }
        }
    },

    methods: {
        intersect() {
            var options = {
                rootMargin: "300px",
                threshold: 0
            };
            var video = this.$refs.video;
            var observer = new IntersectionObserver((entries, observer) => {
                var isIntersecting =
                    typeof entries[0].isIntersecting === "boolean"
                        ? entries[0].isIntersecting
                        : entries[0].intersectionRatio > 0;
                if (isIntersecting) {
                    this.setupVideo();
                    observer.unobserve(video);
                }
            }, options);
            observer.observe(video);
        },

        playVideo() {
            this.video.play();
            this.videoPlaying = true;
        },

        /**
        * Play the intro video
        */
        async setupVideo() {
            const Plyr = await import(/* webpackChunkName: "plyr" */ 'plyr')
                .then(module => {
                    return module.default
                }) // using the default export
                .catch(() => {
                    console.log('Handle error!');
                });

            this.video = new Plyr(this.$refs.video, {
                settings: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
                muted: this.hasLoop,
                loop: {
                    active: this.hasLoop
                },
                autoplay: this.hasLoop,
            });

            if (!this.video) {
                return;
            }

            if (this.hasLoop) {
                this.videoPlaying = true;
            }

            this.video.on('ready', () => {
                if (this.hasLoop) {
                    this.video.play();
                }
            });

            this.video.on('play', (event) => {
                this.videoPlaying = true;
            });

            this.video.on('pause', (event) => {
                this.videoPlaying = false;
            });

            this.video.on('seeking', (event) => {
                this.videoPlaying = true;
            });

            this.video.on('ended', (event) => {
                this.videoPlaying = false;
            });
        },
    }
});
