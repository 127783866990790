import Vue from 'vue';
import _isEmpty from 'lodash/isEmpty';

Vue.component("configurator-payment-options", {
    props: {
        locale: {
            type: String
        },
        editions: {
            type: Array
        },
        initialEdition: {
            type: Object
        },
        initialColor: {
            type: Object
        },
    },

    data() {
        return {
            selectionExpanded: false,
            privatePaymentGroups: [],
            privatePaymentOptions: [],
            corporatePaymentGroups: [],
            corporatePaymentOptions: [],
            paymentGroups: [],
            paymentOptions: [],
            paymentGroupIndex: 0,
            paymentOptionIndex: 0,
            paymentGroup: {},
            paymentOption: {},
            editionOptions: [],
            promotions: [],
            selectedPromotions: [],
            requestType: '',
            versionHandle: '',
            metallic: false,
            canOpen: false,
            disclaimer: '',
            colorHandle: '',
        };
    },

    watch: {

        /**
         * Set canOpen false if there is only one option without promotions or disclaimers.
         * 
         * @param {Array} value 
         * @returns {Void}
         */
        paymentOptions(value) {

            // Check if there's only one and if there's no groups.
            if (value.length === 1 && !this.paymentGroups.length) {

                // Check if the one doesn't have promotions.
                if (value[0].promotions.length === 0) {

                    // Check if it has any disclaimers.
                    if (!value[0].field_paymentOption_disclaimerMetallic && !value[0].field_paymentOption_disclaimerNonMetallic) {

                        // Can't open this one!
                        this.canOpen = false;
                        return;
                    }
                }
            }

            // Can open in any other case.
            this.canOpen = true;
        },

        /**
         * Set payment options on group change and update index.
         * 
         * @param {Object} value 
         * @returns {Void}
         */
        paymentGroup(value) {

            // Fetch all payment options.
            let paymentOptions = [];
            if (this.requestType === 'private') {
                paymentOptions = this.privatePaymentOptions;
            }
            if (this.requestType === 'business') {
                paymentOptions = this.corporatePaymentOptions;
            }

            // Loop groups (if any) to determine payment options by group.
            if (this.paymentGroups.length) {
                for (let paymentGroupIndex in this.paymentGroups) {
                    if (this.paymentGroups[paymentGroupIndex].handle == value.handle) {
                        this.paymentGroupIndex = parseInt(paymentGroupIndex);
                        paymentOptions = paymentOptions.filter((a) => {
                            return a.field_paymentOption_paymentGroup === value.handle;
                        });
                    }
                }
            }

            // Update payment options and reset to the first one.
            this.paymentOptions = paymentOptions;
            this.paymentOptionIndex = 0;
            this.paymentOption = this.paymentOptions[this.paymentOptionIndex];
        },

        /**
         * Send event on paymentOption change and set index.
         * 
         * @param {Object} value 
         * @returns {Void}
         */
        paymentOption(value) {

            // Find index in array to set index property.
            for (let paymentOptionIndex in this.paymentOptions) {
                if (this.paymentOptions[paymentOptionIndex].field_paymentOption_paymentHandle == value.field_paymentOption_paymentHandle) {
                    this.paymentOptionIndex = parseInt(paymentOptionIndex);
                }
            }

            // Set promotions.
            this.setPromotions(true);

            // Update disclaimer.
            this.updateDisclaimer();

            // Add current color to paymentOption.
            if (value && !_isEmpty(value)) {
                value['color'] = this.colorHandle;
            }

            // Send payment option change event.
            EventBus.$emit('configurator-set-payment-option', value);
            this.trackPaymentSelection();

            // Emit event for changed options.
            this.$nextTick(() => {
                EventBus.$emit('configurator-set-promotions', this.promotions);
            });

            // Recalculate total price and emit price update event.
            this.handlePriceChange();

            // Emit color extra cost event as well.
            this.emitColorExtraCostEvent();

            // Emit option price addition event too.
            this.emitOptionPriceAdditionEvent();
        },

        /**
         * Update payment options and set first one active if the request type is set.
         * 
         * @param {String} value 
         * @returns {Void}
         */
        requestType(value) {

            // Set payment options depending on request type.
            if (!value) {
                this.paymentGroups = [];
                return;
            }
            if (value == 'private') {
                this.paymentGroups = this.paymentGroupsWithOptions(this.privatePaymentGroups, this.privatePaymentOptions);
                this.paymentOptions = this.privatePaymentOptions;
            }
            if (value == 'business') {
                this.paymentGroups = this.paymentGroupsWithOptions(this.corporatePaymentGroups, this.corporatePaymentOptions);
                this.paymentOptions = this.corporatePaymentOptions;
            }

            // Reset index and option to first one available.
            this.paymentGroupIndex = 0;
            this.paymentGroup = this.paymentGroups[this.paymentGroupIndex] ?? {};

            if (!this.paymentGroups.length) {
                this.paymentOptionIndex = 0;
                this.paymentOption = this.paymentOptions[this.paymentOptionIndex];
            }

            // Emit request type event.
            EventBus.$emit('configurator-set-request-type', value);
        },

        /**
         * Emit color extra cost event & update disclaimer if the metallic state changes.
         * 
         * @param {Bool} value 
         */
        metallic() {
            this.emitColorExtraCostEvent();
            this.updateDisclaimer();
        },
    },

    mounted() {
        // Set initial metallic property & update metallic property and trigger price calculations and events when it changes.
        this.metallic = parseInt(this.initialColor.field_metalic) ? true : false;

        this.colorHandle = this.initialColor.colorSlug;

        EventBus.$on('configurator-set-color', (color) => {
            this.metallic = parseInt(color.field_metalic) ? true : false;
            this.colorHandle = color.colorSlug;
            this.setPromotions(false);
            this.handlePriceChange();
            this.emitOptionPriceAdditionEvent();
            this.emitColorExtraCostEvent();
        });

        // Update version handle property and trigger price calculations and events when version changes.
        EventBus.$on('configurator-set-version', (version) => {
            this.versionHandle = version ? version.field_version_versionHandle : '';
            this.setPromotions(false);
            this.handlePriceChange();
            this.emitOptionPriceAdditionEvent();
        });

        // Add listener to update edition options when they change and trigger price change.
        EventBus.$on('configurator-set-edition-options', (options) => {

            // Loop options to fill editionOptions with the ones that have been selected.
            this.editionOptions = [];
            for (let option in options) {
                if (options[option].selected) {
                    this.editionOptions.push(options[option]);
                }
            }
            this.setPromotions(false);

            // Recalculate total price and emit event.
            this.handlePriceChange();
        });

        // Set payment options & add listener to update.
        this.setPaymentOptionsFromEdition(this.initialEdition);
        EventBus.$on('configurator-set-edition', (edition) => {
            this.setPaymentOptionsFromEdition(edition);
        });
    },

    methods: {

        /**
         * Sets promotions for the current payment option.
         * 
         * @param {Boolean} isInitialLoad
         * @returns {Void}
         */
        setPromotions(isInitialLoad) {

            // Set promotions array for this edition and pre-select promotions.
            let promotions = this.paymentOption.promotions;
            let selectedPromotions = [];
            for (let promotionIndex in promotions) {
                if (isInitialLoad) {
                    promotions[promotionIndex].selected = parseInt(promotions[promotionIndex].field_selectedByDefault) ? true : false;
                }
                promotions[promotionIndex].priceAddition = this.determinePromotionPriceAddition(promotions[promotionIndex]);
                if (promotions[promotionIndex].selected) {
                    selectedPromotions.push(promotions[promotionIndex].field_promotionHandle);
                }
                if (!isInitialLoad) {
                    EventBus.$emit('configurator-set-promotion', promotions[promotionIndex]);
                }
            }
            this.promotions = promotions;
            this.selectedPromotions = selectedPromotions;
            if (!isInitialLoad) {
                EventBus.$emit('configurator-set-promotions', this.promotions);
            }
        },

        /**
         * Returns only the payment groups that have any payment options in it.
         * 
         * @param {Array} paymentGroups
         * @param {Array} paymentOptions
         * @returns {Array}
         */
        paymentGroupsWithOptions(paymentGroups, paymentOptions) {
            const paymentGroupsWithOptions = paymentGroups.filter((a) => {
                const paymentOptionsInGroup = paymentOptions.filter((b) => {
                    return b.field_paymentOption_paymentGroup === a.handle;
                });
                return (paymentOptionsInGroup.length > 0);
            });

            return paymentGroupsWithOptions;
        },

        /**
         * Takes edition from edition selection (without payment options), then finds and sets the payment options for this component.
         * 
         * @param {Object} edition 
         * @returns {Void}
         */
        setPaymentOptionsFromEdition(edition) {

            // Loop editions with payment options that were passed to this component.
            for (let editionIndex in this.editions) {

                // Compare edition to the edition that was selected.
                if (this.editions[editionIndex].field_commerceHandle == edition.field_commerceHandle) {

                    // Set payment groups for private and corporate.
                    this.privatePaymentGroups = [];
                    let paymentGroups = null;
                    if (this.editions[editionIndex].field_commercePrivatePaymentGroups_jeetffrr) {
                        paymentGroups = JSON.parse(this.editions[editionIndex].field_commercePrivatePaymentGroups_jeetffrr);
                        for (let i = 0; i < paymentGroups.length; i++) {
                            this.privatePaymentGroups = [...this.privatePaymentGroups, {
                                'title': paymentGroups[i].col1,
                                'handle': paymentGroups[i].col2,
                            }];
                        }
                    }
                    this.corporatePaymentGroups = [];
                    if (this.editions[editionIndex].field_commerceCorporatePaymentGroups_xkktuqyg) {
                        paymentGroups = JSON.parse(this.editions[editionIndex].field_commerceCorporatePaymentGroups_xkktuqyg);
                        for (let i = 0; i < paymentGroups.length; i++) {
                            this.corporatePaymentGroups = [...this.corporatePaymentGroups, {
                                'title': paymentGroups[i].col1,
                                'handle': paymentGroups[i].col2,
                            }];
                        }
                    }

                    // Set its payment options as properties.
                    this.privatePaymentOptions = this.editions[editionIndex].field_commercePrivatePaymentOptions;
                    this.corporatePaymentOptions = this.editions[editionIndex].field_commerceCorporatePaymentOptions;
                }
            }

            // Update request type to trigger setting of payment options.
            this.requestType = '';
            this.$nextTick(() => {
                if (this.privatePaymentOptions.length) {
                    this.requestType = 'private';
                    return;
                }
                if (this.corporatePaymentOptions.length) {
                    this.requestType = 'business';
                    return;
                }
                this.paymentOption = {};
            });
        },

        /**
         * Calculates the total price based on base price, metallic color and selected options and promotions.
         * The new price is parsed into the desired price text string and then an event will be emitted.
         * 
         * @returns {Void}
         */
        handlePriceChange() {

            // Disable price indicator if there is no payment option.
            if (!this.paymentOption || _isEmpty(this.paymentOption)) {
                EventBus.$emit('configurator-price-disable');
                return;
            }

            // Emit n/a event if price is n/a.
            if (!this.metallic && parseInt(this.paymentOption.field_paymentOption_priceNA)) {
                EventBus.$emit('configurator-price-na');
                return;
            }

            // Take base price from payment option.
            let totalPrice = parseFloat(this.paymentOption.field_paymentOption_basePrice);

            // With metallic enabled emit n/a event if metallic price is n/a.
            if (this.metallic) {
                if (parseInt(this.paymentOption.field_paymentOption_priceMetallicNA)) {
                    EventBus.$emit('configurator-price-na');
                    return;
                }
            }

            // If a paymentOption has price additions per color, use it!
            if (this.paymentOption.priceAdditionColor && Object.keys(this.paymentOption.priceAdditionColor).length && this.paymentOption.priceAdditionColor[this.colorHandle]) {
                totalPrice += parseFloat(this.paymentOption.priceAdditionColor[this.colorHandle].price);
            }

            // Add the price addition to the total.
            else if (this.metallic) {
                totalPrice += parseFloat(this.paymentOption.field_paymentOption_priceAdditionMetallicColor);
            }

            // Check if a version is set and update price or emit n/a depending on settings.
            if (this.versionHandle && !_isEmpty(this.paymentOption.versionsPrices)) {
                for (let versionPrice in this.paymentOption.versionsPrices) {
                    if (this.versionHandle == this.paymentOption.versionsPrices[versionPrice].field_versionHandle) {
                        if (parseInt(this.paymentOption.versionsPrices[versionPrice].field_priceImpactNA)) {
                            EventBus.$emit('configurator-price-na');
                            return;
                        }
                        totalPrice += parseFloat(this.paymentOption.versionsPrices[versionPrice].field_priceImpact);
                    }
                }
            }

            // Set correct fields depending on metallic selection for option prices.
            let priceImpact = 'field_priceImpactNonMetallic';
            let priceImpactNA = 'field_priceImpactNonMetallicNA';
            if (this.metallic) {
                priceImpact = 'field_priceImpactMetallic';
                priceImpactNA = 'field_priceImpactMetallicNA';
            }

            // Loop options to add to total if needed or end with an n/a event if it makes the price unavailable.
            for (let option in this.editionOptions) {
                for (let optionPrice in this.paymentOption.optionsPrices) {
                    if (this.editionOptions[option].field_optionHandle == this.paymentOption.optionsPrices[optionPrice].field_optionHandle) {
                        if (parseInt(this.paymentOption.optionsPrices[optionPrice][priceImpactNA])) {
                            EventBus.$emit('configurator-price-na');
                            return;
                        }
                        totalPrice += parseFloat(this.paymentOption.optionsPrices[optionPrice][priceImpact]);
                    }
                }
            }

            // Parse price text with the result and emit total-price update event.
            let priceText = this.formatAsPrice(totalPrice);
            if (this.paymentOption.field_paymentOption_priceText) {
                priceText = this.paymentOption.field_paymentOption_priceText.replace('{price}', priceText);
            }
            EventBus.$emit('configurator-total-price-update', this.formatAsPrice(totalPrice), priceText);

            // Loop promotions to add to total if needed or end with an n/a event if it makes the price unavailable.
            for (let promotion in this.promotions) {
                if (this.promotions[promotion].selected) {
                    if (this.determinePromotionPriceNA(this.promotions[promotion])) {
                        EventBus.$emit('configurator-price-na');
                        return;
                    }
                    if (this.determinePromotionPriceActive(this.promotions[promotion])) {
                        totalPrice += this.determinePromotionPriceAddition(this.promotions[promotion]);
                    }
                }
            }

            // Parse price text with the result and emit price update event.
            priceText = this.formatAsPrice(totalPrice);
            if (this.paymentOption.field_paymentOption_priceText) {
                priceText = this.paymentOption.field_paymentOption_priceText.replace('{price}', priceText);
            }
            EventBus.$emit('configurator-price-update', this.formatAsPrice(totalPrice), priceText);
        },

        /**
         * Emit event to indicate color extra cost in case of metallic color.
         * 
         * @returns {Void}
         */
        emitColorExtraCostEvent() {
            // Emit extra cost event with cost if chosen color has additional price, otherwise check if metallic.
            if (this.paymentOption.priceAdditionColor && Object.keys(this.paymentOption.priceAdditionColor).length) {
                if (this.paymentOption.priceAdditionColor[this.colorHandle]) {
                    EventBus.$emit( 
                        'configurator-color-extra-cost',
                        parseFloat(this.paymentOption.priceAdditionColor[this.colorHandle].price),
                        this.formatAsPrice(this.paymentOption.priceAdditionColor[this.colorHandle].price),
                        true,
                        this.paymentOption.priceAdditionColor[this.colorHandle].notification,
                    );
                    return;
                }
            }

            // Emit extra cost event with cost if metallic.
            if (this.metallic && parseFloat(this.paymentOption.field_paymentOption_priceAdditionMetallicColor)) {
                EventBus.$emit(
                    'configurator-color-extra-cost',
                    parseFloat(this.paymentOption.field_paymentOption_priceAdditionMetallicColor),
                    this.formatAsPrice(parseFloat(this.paymentOption.field_paymentOption_priceAdditionMetallicColor)),
                    parseInt(this.paymentOption.field_paymentOption_showMetallicPriceAddition)
                );
                return;
            }

            // Emit 0 extra cost if non-metallic.
            EventBus.$emit('configurator-color-extra-cost', 0, 0, false, null);
        },

        /**
         * Emit events to indicate options price additions.
         * 
         * @returns {Void}
         */
        emitOptionPriceAdditionEvent() {

            // Set correct fields depending on metallic selection.
            let metallic = this.metallic ? 'Metallic' : 'NonMetallic';

            // Loop options for which prices are available for the current payment option.
            for (let optionPrice in this.paymentOption.optionsPrices) {

                // Emit 0 if price is n/a or not set.
                if (parseInt(this.paymentOption.optionsPrices[optionPrice]['field_priceImpact'+ metallic +'NA']) || !this.paymentOption.optionsPrices[optionPrice]['field_priceImpact'+ metallic]) {
                    EventBus.$emit('configurator-option-price-addition', this.paymentOption.optionsPrices[optionPrice].field_optionHandle, 0, 0, 0, 0);
                    return;
                }

                // Emit the price if it there is one otherwise.
                EventBus.$emit(
                    'configurator-option-price-addition',
                    this.paymentOption.optionsPrices[optionPrice].field_optionHandle,
                    parseFloat(this.paymentOption.optionsPrices[optionPrice]['field_priceImpact'+ metallic]),
                    this.formatAsPrice(parseFloat(this.paymentOption.optionsPrices[optionPrice]['field_priceImpact'+ metallic])),
                    parseFloat(this.paymentOption.optionsPrices[optionPrice]['field_shopPriceImpact'+ metallic]),
                    parseInt(this.paymentOption.optionsPrices[optionPrice].field_showPriceImpact)
                );
            }
        },

        /**
         * Update promotion selected property and trigger events if the select state of a promotion changed.
         * 
         * @param {Object} option 
         * @returns {Void}
         */
        onPromotionSelectionChange(promotion) {
            promotion.selected = !promotion.selected;
            EventBus.$emit('configurator-set-promotion', promotion);
            EventBus.$emit('configurator-set-promotions', this.promotions);
            this.updateDisclaimer();
            this.handlePriceChange();
        },

        /**
         * Determine the price addition for a given promotion, based on metallic selection and price settings of the promotion.
         * 
         * @param {Object} promotion 
         * @returns {Float}
         */
        determinePromotionPriceAddition(promotion) {

            // Define price, disclaimer and checkbox copy depending on if there's options or versions prices.
            let priceImpactNonMetallic = promotion.field_priceImpactNonMetallic;
            let priceImpactMetallic = promotion.field_priceImpactMetallic;
            promotion.finalDisclaimerNonMetallic = promotion.field_disclaimerNonMetallic;
            promotion.finalDisclaimerMetallic = promotion.field_disclaimerMetallic;
            promotion.priceAdditionOverruleNonMetallic = promotion.field_priceCopyOverruleNonMetallic;
            promotion.priceAdditionOverruleMetallic = promotion.field_priceCopyOverruleMetallic;
            for (let i = 0; i < this.editionOptions.length; i++) {
                for (let j = 0; j < promotion.optionsPriceAdditions.length; j++) {
                    if (this.editionOptions[i].field_optionHandle === promotion.optionsPriceAdditions[j].field_optionPriceAddition_optionHandle) {
                        priceImpactNonMetallic = promotion.optionsPriceAdditions[j].field_optionPriceAddition_priceImpactNonMetallic;
                        priceImpactMetallic = promotion.optionsPriceAdditions[j].field_optionPriceAddition_priceImpactMetallic;
                        if (promotion.optionsPriceAdditions[j].field_optionPriceAddition_disclaimerNonMetallic) {
                            promotion.finalDisclaimerNonMetallic = promotion.optionsPriceAdditions[j].field_optionPriceAddition_disclaimerNonMetallic;
                        }
                        if (promotion.optionsPriceAdditions[j].field_optionPriceAddition_disclaimerMetallic) {
                            promotion.finalDisclaimerMetallic = promotion.optionsPriceAdditions[j].field_optionPriceAddition_disclaimerMetallic;
                        }
                        if (promotion.optionsPriceAdditions[j].field_optionPriceAddition_priceCopyOverruleNonMetallic) {
                            promotion.priceAdditionOverruleNonMetallic = promotion.optionsPriceAdditions[j].field_optionPriceAddition_priceCopyOverruleNonMetallic;
                        }
                        if (promotion.optionsPriceAdditions[j].field_optionPriceAddition_priceCopyOverruleMetallic) {
                            promotion.priceAdditionOverruleMetallic = promotion.optionsPriceAdditions[j].field_optionPriceAddition_priceCopyOverruleMetallic;
                        }
                    }
                }
            }
            for (let i = 0; i < promotion.versionsPriceAdditions.length; i++) {
                if (this.versionHandle === promotion.versionsPriceAdditions[i].field_versionPriceAddition_versionHandle) {
                    priceImpactNonMetallic = promotion.versionsPriceAdditions[i].field_versionPriceAddition_priceImpactNonMetallic;
                    priceImpactMetallic = promotion.versionsPriceAdditions[i].field_versionPriceAddition_priceImpactMetallic;
                    if (promotion.versionsPriceAdditions[i].field_versionPriceAddition_disclaimerNonMetallic) {
                        promotion.finalDisclaimerNonMetallic = promotion.versionsPriceAdditions[i].field_versionPriceAddition_disclaimerNonMetallic;
                    }
                    if (promotion.versionsPriceAdditions[i].field_versionPriceAddition_disclaimerMetallic) {
                        promotion.finalDisclaimerMetallic = promotion.versionsPriceAdditions[i].field_versionPriceAddition_disclaimerMetallic;
                    }
                    if (promotion.versionsPriceAdditions[i].field_versionPriceAddition_priceCopyOverruleNonMetallic) {
                        promotion.priceAdditionOverruleNonMetallic = promotion.versionsPriceAdditions[i].field_versionPriceAddition_priceCopyOverruleNonMetallic;
                    }
                    if (promotion.versionsPriceAdditions[i].field_versionPriceAddition_priceCopyOverruleMetallic) {
                        promotion.priceAdditionOverruleMetallic = promotion.versionsPriceAdditions[i].field_versionPriceAddition_priceCopyOverruleMetallic;
                    }
                }
            }

            // Handle non-metallic, return 0 if nothing is set or a formatted price.
            if (!this.metallic) {
                promotion.priceAdditionOverrule = promotion.priceAdditionOverruleNonMetallic;
                if (priceImpactNonMetallic) {
                    return parseFloat(priceImpactNonMetallic);
                }
                return 0;
            }

            // Handle metallic, return 0 if nothing is set or a formatted price.
            promotion.priceAdditionOverrule = promotion.priceAdditionOverruleMetallic;
            if (priceImpactMetallic) {
                return parseFloat(priceImpactMetallic);
            }
            return 0;
        },

        /**
         * Determine if the price for a given promotion is n/a.
         * 
         * @param {Object} promotion 
         * @returns {Bool}
         */
         determinePromotionPriceNA(promotion) {

            // Handle non-metallic.
            if (!this.metallic) {
                return parseInt(promotion.field_priceImpactNonMetallicNA) ? true : false;
            }

            // Handle metallic.
            return parseInt(promotion.field_priceImpactMetallicNA) ? true : false;
        },

        /**
         * Determine if the price for a given promotion is active.
         * 
         * @param {Object} promotion 
         * @returns {Bool}
         */
         determinePromotionPriceActive(promotion) {

            // Handle non-metallic.
            if (!this.metallic) {
                return parseInt(promotion.field_priceImpactNonMetallicInactive) ? false : true;
            }

            // Handle metallic.
            return parseInt(promotion.field_priceImpactMetallicInactive) ? false : true;
        },

        /**
         * Determine disclaimer to be shown depening on metallic color and promotion overrulings.
         * 
         * @returns {Void}
         */
         updateDisclaimer() {

            // Set disclaimer from payment option
            let disclaimer = this.paymentOption.field_paymentOption_disclaimerNonMetallic;
            if (this.metallic) {
                disclaimer = this.paymentOption.field_paymentOption_disclaimerMetallic;
            }

            // Check promotions.
            for (let promotion in this.promotions) {
                if (this.promotions[promotion].selected) {
                    if (!this.metallic && this.promotions[promotion].field_alternatePaymentOptionDisclaimerNonMetallic) {
                        disclaimer = this.promotions[promotion].field_alternatePaymentOptionDisclaimerNonMetallic;
                    }
                    if (this.metallic && this.promotions[promotion].field_alternatePaymentOptionDisclaimerMetallic) {
                        disclaimer = this.promotions[promotion].field_alternatePaymentOptionDisclaimerMetallic;
                    }
                }
            }

            // Set disclaimer.
            this.disclaimer = disclaimer;
            
            // Send disclaimer change event.
            EventBus.$emit('configurator-set-payment-disclaimer', disclaimer);
        },

        /**
         * Formats a given float as a price, with no decimals if there are none, or 2 if there are.
         * @param {Float} price 
         * @returns {String}
         */
         formatAsPrice(price) {
            // Set localization formatting.
            let countyPriceMapping = {
                localization: this.locale,
                showZeros: false,
            };
            if (this.$parent.countyPriceMapping[this.locale] != undefined) {
                countyPriceMapping = this.$parent.countyPriceMapping[this.locale];
            }

            // Format with 2 decimals if price as any decimals.
            if (price % 1 != 0 || countyPriceMapping.showZeros) {
                return price.toLocaleString(countyPriceMapping.localization, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }

            // Format with no decimal digits.
            return price.toLocaleString(countyPriceMapping.localization);
        },

        /**
         * Track model selection if any tracker who wants it is loaded.
         * 
         * @return {Void}
         */
        trackPaymentSelection() {
            if (typeof p161 === 'object') {
                let pixelId = '';
                let paymentOption = 'not-recognised';
                let requestType = 'private';

                if (this.requestType === 'business') {
                    requestType = 'company';
                }

                if (this.$parent.siteHandle === 'mgMotorsNetherlands') {
                    pixelId = '3161293';
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'cash' || this.paymentOption.field_paymentOption_paymentHandle === 'businesscash') {
                        paymentOption = 'buy';
                    }
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'privateLease') {
                        paymentOption = 'lease';
                    }
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'companyLease') {
                        paymentOption = 'financial-lease';
                    }
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'operationalLease') {
                        paymentOption = 'operational-lease';
                    }
                }

                if (this.$parent.siteHandle === 'mgMotorsNorway') {
                    pixelId = '3161322';
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'cash') {
                        paymentOption = 'buy';
                    }
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'privateLease') {
                        paymentOption = 'lease';
                    }
                    if (this.paymentOption.field_paymentOption_paymentHandle === 'companyLease') {
                        paymentOption = 'financial-lease';
                        requestType = 'company';
                    }
                }

                if (pixelId !== '') {
                    p161.track(pixelId, {
                        customer_extra: this.$parent.model + '_' + this.$parent.configuratorResult.color.key + '_' + this.$parent.configuratorResult.type.key + '_' + paymentOption,
                        customer_id: requestType
                    });
                    if (typeof window.trackQueue === 'function') {
                        try {
                            window.trackQueue(p161);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }
            }
        },
    },
});
