import Vue from 'vue';

Vue.component("configurator-price-indicator", {
    data() {
        return {
            indicatorEnabled: false,
            priceAvailable: true,
            directPaymentEnabled: false,
            priceText: '',
        };
    },

    mounted() {

        // Update price text and enable indicator if a new price is set.
        EventBus.$on('configurator-price-update', (priceText, formattedPriceText) => {
            this.priceText = formattedPriceText;
            this.priceAvailable = true;
            this.indicatorEnabled = true;
        });

        // Set price n/a and enable indicator if a new price is unavailable.
        EventBus.$on('configurator-price-na', () => {
            this.priceText = '';
            this.priceAvailable = false;
            this.indicatorEnabled = true;
        });

        // Disable price indicator if so required.
        EventBus.$on('configurator-price-disable', () => {
            this.indicatorEnabled = false;
        });

        // Enable or disable buy bow button.
        EventBus.$on('configurator-set-payment-option', (paymentOption) => {
            this.directPaymentEnabled = paymentOption.field_paymentOption_paymentEnabled === '1';
        });
    },
});
