import Errors from "../classes/errors";
import Vue from "vue";
import _throttle from "lodash/throttle";
import _orderBy from "lodash/orderBy";

Vue.component("contact-form-component", {
    props: {
        siteHandle: {
            value: String
        },
        stores: {
            value: Object
        },
        brandstoreResultAmount: {
            value: Object
        }
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            formCompleted: false,
            submitting: false,
            requestType: "private",
            requestIndex: 0,
            countryHandle: "",
            selected: "",
            selectedBrandStore: "",

            dropdownCountry: null,
            dropdownSubject: null,
            dropdownStore: null,

            // Dealer search (zip code vars)
            searchBrandstore: "",
            zipCoords: null,
            searchQueryIsDirty: false,
            nearbyProximityStores: null,
            // Error vars for zip api request
            needMoreInput: false,
            apiRequestLoading: false,
            countryValue: {
                mgMotorsNetherlands: "NL",
                mgMotorsBelgiumNl: "BE",
                mgMotorsBelgiumFr: "BE",
                mgMotorsAustria: "AT",
                mgMotorsDanmark: "DK",
                mgMotorsGermany: "DE",
                mgMotorsSpain: "ES",
                mgMotorsFrance: "FR",
                mgMotorsIceland: "IS",
                mgMotorsItaly: "IT",
                mgMotorsLuxembourgFr: "LU",
                mgMotorsNorway: "NO",
                mgMotorsPortugal: "PT",
                mgMotorsSweden: "SE"
            }
        };
    },

    computed: {
        moreThenTwoStores: function() {
            if (!this.countryHandle) {
                return false;
            }

            return Object.keys(this.stores[this.countryHandle]).length > 2;
        },

        hasBrandStores: function() {
            if (!this.countryHandle) {
                return false;
            }

            return Object.keys(this.stores[this.countryHandle]).length > 0;
        },
        orderedStores: function() {
            return _orderBy(this.stores[this.countryHandle], "title");
        }
    },

    watch: {
        searchBrandstore() {
            if (this.searchBrandstore.length < 4) {
                this.needMoreInput = true;
                this.nearbyProximityStores = null;
                return;
            }

            if (this.selectedBrandStore) {
                if (this.selectedBrandStore.title === this.searchBrandstore) {
                    return;
                }
            }

            this.selectedBrandStore = null;
            this.needMoreInput = false;
            this.searchByZipGoogleApi();
        },

        countryHandle() {
            this.showPlanNl = false;
            // if (this.countryHandle == 'mgMotorsNetherlands') {
            //     this.showPlanNl = true;
            //     this.dropdownCar = null;
            // }
            this.selectedBrandStore = null;
            this.dropdownStore = null;
        }
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        if (this.siteHandle !== "default") {
            this.countryHandle = this.siteHandle;
        }

        if (this.siteHandle == "mgMotorsGermany") {
            this.selected = "become-a-distributor";
        }
        this.injectGoogleMaps();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        injectGoogleMaps() {
            var tag = document.createElement("script");
            tag.setAttribute("type", "text/javascript");
            tag.defer = true;
            tag.async = true;
            tag.setAttribute(
                "src",
                "//maps.google.com/maps/api/js?key=AIzaSyBXLteX7ErcVvjvFLHB1_TMCzq55wVb5Bg&libraries=places&callback=mapsCallback"
            );
            (
                document.getElementsByTagName("head")[0] ||
                document.documentElement
            ).appendChild(tag);
        },

        searchByZipGoogleApi: _throttle(function() {
            this.makeApiRequest();
        }, 1000),

        makeApiRequest() {
            this.apiRequestLoading = true;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    componentRestrictions: {
                        country: this.countryValue[this.siteHandle]
                    },
                    address: encodeURI(this.searchBrandstore)
                },
                (results, status) => {
                    if (status === "OK") {
                        if (results[0]) {
                            this.apiRequestLoading = false;
                            this.zipCoords = {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            };

                            if (
                                !this.zipCoords ||
                                this.stores[this.countryHandle].lengh < 1
                            ) {
                                return;
                            }

                            this.getNearbyBrandstores(
                                this.zipCoords,
                                this.stores[this.countryHandle]
                            );
                        }
                        return null;
                    } else {
                        console.error(
                            "Geocode was not successful for the following reason: " +
                                status
                        );
                        if (status === "ZERO_RESULTS") {
                            this.apiRequestLoading = false;
                        }
                    }
                }
            );
        },

        getNearbyBrandstores(userLocation, brandstores) {
            const storesWithDistance = Object.values(brandstores).map(store => {
                const distance = this.getDistance(userLocation, store);
                return {
                    ...store,
                    distance
                };
            });

            this.nearbyProximityStores = storesWithDistance
                .sort((a, b) => {
                    if (a.distance < b.distance) {
                        return -1;
                    }

                    if (a.distance > b.distance) {
                        return 1;
                    }

                    return 0;
                })
                .slice(0, this.brandstoreResultAmount[this.countryHandle]);
        },

        getDistance(userLocation, brandstore) {
            // Bron: https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
            var radius = 6371.071; // Radius of the Earth in KM

            const radiusLat1 = userLocation.lat * (Math.PI / 180); // Convert degrees to radians
            const radiusLat2 = brandstore.lat * (Math.PI / 180); // Convert degrees to radians
            const differenceLat = radiusLat2 - radiusLat1; // Radian difference (latitudes)
            const differenceLng =
                (brandstore.lng - userLocation.lng) * (Math.PI / 180); // Radian difference (longitudes)

            const distance =
                2 *
                radius *
                Math.asin(
                    Math.sqrt(
                        Math.sin(differenceLat / 2) *
                            Math.sin(differenceLat / 2) +
                            Math.cos(radiusLat1) *
                                Math.cos(radiusLat2) *
                                Math.sin(differenceLng / 2) *
                                Math.sin(differenceLng / 2)
                    )
                );

            return distance;
        },

        replaceInputValue(e) {
            this.selectedBrandStore = this.stores[this.countryHandle][
                e.target.dataset.value
            ];
            this.searchBrandstore = this.selectedBrandStore.title;

            // Reset zipCoords to null
            this.zipCoords = null;
        },

        showDropdown(key) {
            if (!this.hasBrandStores) {
                return false;
            }

            if (!this.countryHandle) {
                return false;
            }

            //In this new design, it's always a dropdown
            // if (!this.moreThenTwoStores) {
            //     return false;
            // }

            if (this.countryHandle == key) {
                return true;
            }
        },

        showRadioButtons(key) {
            if (!this.hasBrandStores) {
                return false;
            }

            if (!this.countryHandle) {
                return false;
            }

            if (this.moreThenTwoStores) {
                return false;
            }

            if (this.countryHandle == key) {
                return true;
            }
        },
        scrollToFirstError() {
            Vue.nextTick(() => {
                let firstError = document.querySelector(".form-label--error");
                zenscroll.center(firstError);
            });
        },

        scrollToTop() {
            Vue.nextTick(() => {
                zenscroll.toY(0);
            });
        },

        onSubmit(event) {
            const form = event.currentTarget;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            grecaptcha.ready(() => {
                grecaptcha
                    .execute("6LeCf8AZAAAAAEUasZwzVxRkrUX8bW-WQxTKqDYo", {
                        action: "submit"
                    })
                    .then(token => {
                        // Add your logic to submit to your backend server here.

                        form.querySelector(
                            "#recaptchaTokenField"
                        ).value = token;

                        axios
                            .post("/", new FormData(form))
                            .then(response => {
                                const result = response.data;

                                if (result.data.errors) {
                                    this.submitting = false;
                                    this.errors.clear();
                                    this.errors.record(result.data.errors);

                                    this.scrollToFirstError();
                                    return;
                                }

                                // Success
                                // this.formCompleted = true;
                                this.errors.clear();

                                this.sendGTMData();

                                window.location = this.$refs.redirectUrl.value;
                                // this.scrollToTop();
                            })
                            .catch(error => {
                                this.submitting = false;
                                console.log(error.response);
                            });
                    });
            });
        },

        sendGTMData() {
            const country =
                this.siteHandle == "default"
                    ? "mgMotorsEurope"
                    : this.siteHandle;

            if (Vue.config.devtools == false && typeof fbq === "function") {
                // check if you are on production-enviroment
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                window.dataLayer.push({
                    event: "contacts",
                    model: " ",
                    edition: " ",
                    page_path: window.location.href,
                    country: `${country} - ${this.$root.languageCode}`
                });
            }
        },

        toggleDropdown(event) {
            let element = event.currentTarget;

            while ((element = element.parentElement)) {
                if (element.classList.contains("dropdown")) {
                    element.classList.toggle("dropdown--active");
                }
            }
        },

        setDropdownCountry(event) {
            this.dropdownCountry = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.countryHandle = value;
        },

        setDropdownSubject(event) {
            this.dropdownSubject = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.selected = value;
        },

        setDropdownStore(event) {
            this.dropdownStore = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.selectedBrandStore = value;
        }
    }
});
