import Vue from 'vue';
import Swiper from 'swiper';

Vue.component('tabslider', {

    data() {
        return {
            tabIndex: 0,
            sectionIndex: 0,
        };
    },

    mounted() {
        this.initSwiper();
    },

    methods: {
        switchSection(index) {
            if (index != this.tabIndex){
                this.tabIndex = index;
                this.sectionIndex = index;

                setTimeout(() => this.initSwiper(), 100);
            }
            
        },

        initSwiper() {
            const swiper = new Swiper('.page-slider', {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + (index + 1) + '</span>';
                    }
                },
                navigation: {
                    nextEl: '.page-slider-next',
                    prevEl: '.page-slider-prev',
                }
            })
        },
    },
});
