import Vue from 'vue';
import ThreeSixty from '@mladenilic/threesixty.js';

Vue.component('image-360-component', {
    props: {
        image: {
            type: String
        },

        maxImagesIndex: {
            type: Number
        }
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentImageIndex: '1',
            requestFrame: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.draggableHolder = this.$el.querySelector('.image-360-block__draggable-holder');
        this.draggableContainer = this.$el.querySelector('.image-360-block__draggable');
        this.imageHolder = this.draggableContainer.querySelector('.image-360-block__image-holder');

        if (this.image) {
            this.image360 = new ThreeSixty(this.imageHolder, {
                image: this.image,
                count: this.maxImagesIndex,
                perRow: 3,
                width: 1900,
                height: 850,
                aspectRatio: 850 / 1900,
                keys: true,
                draggable: true,
                swipeable: true,
                dragTolerance: 10,
                swipeTolerance: 10,
                swipeTarget: this.draggableContainer,
                inverted: true,
            });
        }


        this.draggableHolder.addEventListener('mousedown', () => {
            this.start();
            this.draggableHolder.classList.add('is-dragging');
        });

        this.draggableHolder.addEventListener('mouseup', () => {
            this.stop();
            this.draggableHolder.classList.remove('is-dragging');
        });

        this.draggableHolder.addEventListener('touchstart', () => {
            this.start();
            this.draggableHolder.classList.add('is-dragging');
        });

        this.draggableHolder.addEventListener('touchend', () => {
            this.stop();
            this.draggableHolder.classList.remove('is-dragging');
        });
    },

    watch: {},

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        handleCurrentIndex() {
            if (!this.looping) {
                window.cancelAnimationFrame(this.handleCurrentIndex);
                return
            }

            if (this.currentImageIndex !== this.image360.index) {
                this.currentImageIndex = this.image360.index;
            }

            this.requestFrame = window.requestAnimationFrame(this.handleCurrentIndex);
        },

        start() {
            this.looping = true;
            this.requestFrame = window.requestAnimationFrame(this.handleCurrentIndex);
        },

        stop() {
            this.looping = false;
        },

        handleGoTo() {
            this.image360.goto(Number(this.currentImageIndex));
        },
    },

    onDestroy() {
        this.draggableHolder.removeEventListener('mousedown');
        this.draggableHolder.removeEventListener('mouseup');
        this.draggableHolder.removeEventListener('touchstart');
        this.draggableHolder.removeEventListener('touchend');
    }
});
