import Vue from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

Vue.component('in-page-navigation-component', {
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            allNavigationItems: [],
            allNavItems: null,
            selected: null,
            currentIndex: null,
            navIsBlack: false,
            pastLastSection: false,
            prevActive: null,
            activeTimer: null
        };
    },

    watch: {
    },

    computed: {
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        this.allNavigationItems = document.querySelectorAll('[data-page-nav]');
        this.allNavItems =  this.initPageNav();
        this.detectSection();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initPageNav() {
            if (!this.allNavigationItems) {
                return;
            }

            const arr = [];
            for (let i = 0; i < this.allNavigationItems.length; i++) {
                const obj = {
                    title: this.allNavigationItems[i].getAttribute('data-page-nav'),
                    anchor: `#${this.allNavigationItems[i].getAttribute('id')}`
                };
                arr.push(obj);
            }

            return arr;
        },

        detectSection() {
            if (!this.allNavigationItems) {
                return;
            }

            for (let i = 0; i < this.allNavigationItems.length; i++) {
                const tlDown = gsap.timeline({
                    scrollTrigger: {
                        trigger: this.allNavigationItems[i],
                        start: 'top center',
                        end: 'bottom 75%',
                        onEnter: ({ progress, direction, isActive }) => {
                            this.currentIndex = i;
                            this.handleTimer(i);
                        },
                        onLeave: () => {
                            if (i === (this.allNavigationItems.length - 1)) {
                                this.pastLastSection = true;
                            } else {
                                this.pastLastSection = false;
                            }
                        }
                    }
                });

                const tlUp = gsap.timeline({
                    scrollTrigger: {
                        trigger: this.allNavigationItems[i],
                        start: 'bottom center',
                        end: 'top 25%',
                        onEnterBack: ({ progress, direction, isActive }) => {
                            this.currentIndex = i;
                            this.handleTimer(i);
                            if (i === (this.allNavigationItems.length - 1)) {
                                this.pastLastSection = false;
                            }
                        },
                        onLeave: () => {
                            if (i === (this.allNavigationItems.length - 1)) {
                                this.pastLastSection = true;
                            } else {
                                this.pastLastSection = false;
                            }
                        }
                    }
                })
            }
        },

        handleMouseEnter(e) {
            const el = e.target;
            const isActive = el.classList.contains('active');

            if (isActive) {
                return;
            }

            const siblings = el.parentNode.children;
            for (let i = 0; i < siblings.length; i++) {
                if (siblings[i].classList.contains('active')) {
                    // Set currentIndex to null to prevent active class
                    this.currentIndex = null;
                    // Remove active class from current active
                    siblings[i].classList.remove('active');
                }
            }
        },

        handleMouseLeave(e) {
            const el = e.target;
            const siblings = el.parentNode.children;

            let isActive = false;
            for (let i = 0; i < siblings.length; i++) {
                if (siblings[i].classList.contains('active')) {
                    // Set isActive to true if user has clicked on an nav item
                    isActive = true;
                }
            }
        },

        handleTimer() {
            if (this.activeTimer) {
                clearTimeout(this.activeTimer);
            }

            this.activeTimer = setTimeout(() => {
                this.currentIndex = null;
            }, 3000);
        }
    },
    beforeDestroy() {
    }
});
