import Vue from 'vue';

Vue.component('travel-distance', {

    data() {
        return {
            tabIndex: 0,
        };
    },

    mounted() { },

    methods: {
        switchTab(index) {
            if (index != this.tabIndex) {
                this.tabIndex = index;
            }

        },
    },
});
