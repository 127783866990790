import Vue from 'vue';
import Headroom from "headroom.js";

Vue.component('mobile-cta-bar', {

    mounted() {
        // grab an element
        var myElement = document.querySelector(".mobile-cta-bar");
        // construct an instance of Headroom, passing the element
        var headroom  = new Headroom(myElement);
        // initialise
        headroom.init();
    },

    methods: {
        
    },
});
