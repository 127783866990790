import Vue from 'vue';

Vue.component("configurator", {
    props: {
        siteHandle: {
            type: String
        },
        model: {
            type: String
        },
        initialDisclaimer: {
            type: String
        },
    },

    data() {
        return {
            configurationCompleted: false,
            disclaimer: '',
            configuratorResult: {
                color: '',
                type: '',
                options: {},
                promotions: {},
                version: {},
                price: '',
                totalPriceIndication: '',
                priceIndication: '',
                disclaimer: '',
            },
            hasInteriorImage: true,
            countyPriceMapping: {
                'be-FR': {
                    localization: 'nl-NL',
                    showZeros: false,
                },
                'fr-FR': {
                    localization: 'nl-NL',
                    showZeros: false,
                },
                'is-IS': {
                    localization: 'nl-NL',
                    showZeros: false,
                },
                'de-DE': {
                    localization: 'de-DE',
                    showZeros: true,
                },
                'de-AT': {
                    localization: 'de-AT',
                    showZeros: true,
                },
            }
        };
    },

    watch: {

        /**
         * Track & send results and switch to dark mode if configuration has been completed, or go back.
         * 
         * @param {Boolean} value 
         */
        configurationCompleted(value) {
            if (value) {
                this.trackConfiguration();
                EventBus.$emit('configurator-completed', this.configuratorResult);
                this.$root.darkColorSchemeSwitch = true;
                return;
            }
            this.$root.darkColorSchemeSwitch = false;
        },
    },

    created() {

        // Handle main disclaimer text.
        this.handleMainDisclaimer();

        // Listen to the events of children to update the result.
        this.updateResultOnChildEvents();

        // Set configurator uncompleted on event.
        EventBus.$on('configurator-back-to-the-studio', () => {
            this.configurationCompleted = false;
        });
    },

    methods: {

        /**
         * Sets main disclaimer and updates it on payment option change if required.
         * 
         * @returns {Void}
         */
        handleMainDisclaimer() {
            
            // Set initial disclaimer.
            this.disclaimer = this.initialDisclaimer;

            // Update main disclaimer if an option has been selected that wants to overrule it.
            EventBus.$on('configurator-set-payment-option', (paymentOption) => {
                this.disclaimer = this.initialDisclaimer;
                if (paymentOption.field_paymentOption_alternateDisclaimer) {
                    this.disclaimer = paymentOption.field_paymentOption_alternateDisclaimer;
                }
            });
        },

        /**
         * Update the configuratorResult object when something changes in one of the children components.
         * 
         * @returns {Void}
         */
        updateResultOnChildEvents() {

            // Update color on color selection change.
            EventBus.$on('configurator-set-color', (color) => {
                this.configuratorResult.color = {
                    'key': color.colorSlug,
                    'name': color.colorTitle,
                    'metallic': color.field_metalic,
                    'productCode': color.colorProductCode,
                };
            });

            // Update version on version selection change.
            EventBus.$on('configurator-set-version', (version) => {
                this.configuratorResult.version = {
                    'key': version.field_version_versionHandle,
                    'name': version.field_version_versionTitle,
                    'productCode': version.field_version_productCode,
                };
            });

            // Update type on edition selection change.
            EventBus.$on('configurator-set-edition', (edition) => {
                this.configuratorResult.type = {
                    'id': edition.id,
                    'key': edition.field_commerceHandle,
                    'name': edition.title,
                    'productCode': edition.field_commerceProductCode,
                };
                this.hasInteriorImage = edition.field_commerceNoInteriorImage_bjgvrydr === '1' ? false : true;
            });

            // Update options on option configuration change.
            EventBus.$on('configurator-set-edition-options', (options) => {
                this.options = {};
                for (let option in options) {
                    this.configuratorResult.options[options[option].field_optionHandle] = {
                        'name': options[option].field_optionName,
                        'selected': options[option].selected,
                        'shopPriceAddition': options[option].shopPriceAddition,
                        'productCode': options[option].field_productCode,
                    };
                }
            });

            // Update promotions on promotion configuration change.
            EventBus.$on('configurator-set-promotions', (promotions) => {
                this.promotions = {};
                for (let promotion in promotions) {

                    // Define price depending on if there's options prices.
                    let priceImpactNonMetallic = promotions[promotion].field_shopPriceImpactNonMetallic;
                    let priceImpactMetallic = promotions[promotion].field_shopPriceImpactMetallic;
                    for (const [optionHandle, option] of Object.entries(this.configuratorResult.options)) {
                        for (let j = 0; j < promotions[promotion].optionsPriceAdditions.length; j++) {
                            if (option.selected && optionHandle === promotions[promotion].optionsPriceAdditions[j].field_optionPriceAddition_optionHandle) {
                                priceImpactNonMetallic = promotions[promotion].optionsPriceAdditions[j].field_optionPriceAddition_shopPriceImpactNonMetallic;
                                priceImpactMetallic = promotions[promotion].optionsPriceAdditions[j].field_optionPriceAddition_shopPriceImpactMetallic;
                            }
                        }
                    }
                    for (let i = 0; i < promotions[promotion].versionsPriceAdditions.length; i++) {
                        if (this.configuratorResult.version.key === promotions[promotion].versionsPriceAdditions[i].field_versionPriceAddition_versionHandle) {
                            priceImpactNonMetallic = promotions[promotion].versionsPriceAdditions[i].field_versionPriceAddition_shopPriceImpactNonMetallic;
                            priceImpactMetallic = promotions[promotion].versionsPriceAdditions[i].field_versionPriceAddition_shopPriceImpactMetallic;
                        }
                    }

                    this.configuratorResult.promotions[promotions[promotion].field_promotionHandle] = {
                        'name': promotions[promotion].field_promotionName,
                        'selected': promotions[promotion].selected,
                        'shopPriceImpactMetallic': priceImpactMetallic,
                        'shopPriceImpactNonMetallic': priceImpactNonMetallic,
                    };
                }
            });

            // Update price on paymentOption selection change.
            EventBus.$on('configurator-set-payment-option', (paymentOption) => {
                this.configuratorResult.price = {
                    'key': paymentOption.field_paymentOption_paymentHandle,
                    'name': paymentOption.field_paymentOption_paymentName,
                    'shopPrice': paymentOption.field_paymentOption_shopPrice,
                    'shopPriceAdditionMetallic': paymentOption.field_paymentOption_shopPriceAdditionMetallic,
                    'shopPriceAdditionSpecificColor': (paymentOption.priceAdditionColor && paymentOption.priceAdditionColor[paymentOption.color]) ? paymentOption.priceAdditionColor[paymentOption.color].price : null,
                };
            });

            // Update disclaimer on payment disclaimer change.
            EventBus.$on('configurator-set-payment-disclaimer', (disclaimer) => {
                this.configuratorResult.disclaimer = disclaimer;
            });

            // Update totalPriceIndication on total price update.
            EventBus.$on('configurator-total-price-update', (totalPrice, formattedTotalPrice) => {
                this.configuratorResult.totalPriceIndication = formattedTotalPrice.replace(/(<([^>]+)>)/gi, " ");
            });

            // Update priceIndication on price update.
            EventBus.$on('configurator-price-update', (price, formattedPrice) => {
                this.configuratorResult.priceIndication = formattedPrice.replace(/(<([^>]+)>)/gi, " ");
            });
        },

        /**
         * Trigger non-direct payment for the order while completing the configurator.
         * 
         * @returns {Void}
         */
        completeConfigurator() {
            EventBus.$emit('configurator-set-direct-payment', false);
            this.configurationCompleted = true;
        },

        /**
         * Trigger direct payment for the order while completing the configurator.
         * 
         * @returns {Void}
         */
        completeConfiguratorWithDirectPayment() {

            // Trigger direct payment event.
            EventBus.$emit('configurator-set-direct-payment', true);

            // Clear existing cart if any.
            let clearCartData = new FormData();
            clearCartData.append('action', 'mg-business-logic/request-offer/clear-cart');
            clearCartData.append(window.csrfTokenName, window.csrfTokenValue);
            axios
                .post("/", clearCartData)
                .then(response => {

                    // Add product variant to cart.
                    let cartData = new FormData();
                    cartData.append('action', 'commerce/cart/update-cart');
                    cartData.append('purchasableId', this.configuratorResult.type.id);
                    cartData.append(window.csrfTokenName, window.csrfTokenValue);
                    axios
                        .post("/", cartData)
                        .catch(error => {
                            console.log(error.response);
                        });
                })
                .catch(error => {
                    console.error(error.response);
                });

            // Complete configurator.
            this.configurationCompleted = true;
        },

        /**
         * Send configuration completion to trackers.
         * 
         * @returns {Void}
         */
        trackConfiguration() {
            // Only track in production environment.
            if (Vue.config.devtools == false && typeof fbq === 'function') {

                fbq('trackCustom', 'CompleteConfiguration', {
                    'car_type': this.model
                });

                // Azerion tracking for NL.
                if (this.siteHandle == 'mgMotorsNetherlands') {
                    this.$root.insertTrackingImageAzerion('https://p1.zemanta.com/p/15834/10264');

                    const sas_tmstp = Math.round(Math.random()*10000000000);
                    const showHeroesUrl = 'https://www8.smartadserver.com/h/tp?advid=450139&trcid=31688&val=&transid=&ref=&uid=&tmstp='+sas_tmstp+'';
                    this.$root.insertTrackingImageShowHeroes(showHeroesUrl);
                }

                // Floodlight tracking for NL, LU, BE.
                if (this.siteHandle == 'mgMotorsNetherlands' || this.siteHandle == 'mgMotorsLuxembourgFr' || this.siteHandle == 'mgMotorsBelgiumNl' || this.siteHandle == 'mgMotorsBelgiumFr') {
                    const axel = Math.random() + "";
                    const a = axel * 10000000000000;
                    const iframe = document.createElement('iframe');
                    const url = 'https://10256359.fls.doubleclick.net/activityi;src=10256359;type=mg_co0;cat=mg_le02;u1=' + this.siteHandle + ';u2=' + this.$root.languageCode + ';u3=' + window.location + ';u4=;u8=;u9=zs-ev;u10=' + this.configuratorResult.color.key + ';u11=' + this.configuratorResult.type.key + ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?'
                    iframe.setAttribute('src', url);
                    iframe.setAttribute('width', 1);
                    iframe.setAttribute('height', '1');
                    iframe.setAttribute('frameborder', 0);
                    iframe.style.display = 'none';
                    document.body.appendChild(iframe);
                }

                // Google tracking for FR.
                if (this.siteHandle == 'mgMotorsFrance') {
                    gtag('event', 'conversion', { 'allow_custom_scripts': true, 'u1': 'zsev', 'send_to': 'DC-10256326/mg-autos/fr_in004+unique'}); // france - FR_INI_Counter_Offer 
                }

                // Google tracking for NO.
                if (this.$root.handle == 'mgMotorsNorway') {
                    gtag('event', 'conversion', { 'allow_custom_scripts': true, 'send_to': 'DC-10210913/conve0/no_le001+standard'}); // Norway - NO_INI_Lead_Offer 
                }

                // Platform 161 tracking for NL / NO.
                if (typeof p161 === 'object') {
                    let pixelId = '';
                    if (this.siteHandle === 'mgMotorsNetherlands') {
                        pixelId = '3161294';
                    }
                    if (this.siteHandle === 'mgMotorsNorway') {
                        pixelId = '3161323';
                    }
                    if (pixelId !== '') {
                        p161.track(pixelId, {customer_extra: this.model + '_' + this.configuratorResult.color.key + '_' + this.configuratorResult.type.key});
                    }
                }
            }
        },
    }
});
