import Swiper from 'swiper';
import Vue from 'vue';
import { parse } from 'date-fns';

Vue.component('thumb-slider', {
    props: [
        'countdownDates',
        'totalSlides'
    ],

    data() {
        return {
            currentSlide: 0,
            galleryThumbs: null,
            galleryTop: null,
            countdownDatesObj: [],

            total: [null],
            seconds: [0],
            minutes: [0],
            hours: [0],
            days: [0],
            deadlineReached: [false],
            parsedDeadline: [],
            remainder: [],
            timeinterval: [],
            countdowns: []
        };
    },

    computed: {
    },

    mounted() {
        this.initSwiper();

        // this.countdownDatesObj = JSON.parse(this.countdownDates);
        // for (let index = 0; index < this.countdownDatesObj.length; index++) {
        //     const element = this.countdownDatesObj[index];
        //     if (element) {
        //         this.initCountdown(element, index);
        //     }
        // }

        const countdowns = [...document.querySelectorAll('.swiper-slide .countdown')];
        for (let index = 0; index < countdowns.length; index++) {
            const element = countdowns[index];
            this.initCountdown(element, index);
        }

        window.addEventListener('scroll', () => {
            if (window.pageYOffset < 300) {
                this.galleryTop.autoplay.start();
            } else {
                this.galleryTop.autoplay.stop();
            }
        });
    },

    methods: {
        initCountdown(element, index) {
            var result = parse(
                element.dataset.date,
                'yyyy-MM-dd hh:mm:ss',
                new Date()
            )

            this.countdowns.push({
                element,
                deadline: result,
                remainder: this.remainingTime(element),
                interval: setInterval(this.updateCountdown, 1000, index),
                total: null
            });

            this.updateCountdown(index);
        },

        remainingTime(element) {
            function parseDate(date) {
                const parsed = Date.parse(date);
                if (!isNaN(parsed)) {
                  return parsed;
                }
                return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
            }
            const total = parseDate(element.dataset.date) - parseDate(new Date()); // 1486635738000 time in ms
            // const total = Date.parse(element.dataset.date) - Date.parse(new Date());

            if (total <= 0) {
                EventBus.$emit('deadline-has-expired', true);
                this.deadlineReached[index] = true;
                return;
            }

            element.querySelector('.--seconds').innerHTML = ('0' + Math.floor( (total / 1000) % 60 )).slice(-2);
            element.querySelector('.--minutes').innerHTML = ('0' + Math.floor( (total / 1000 / 60) % 60 )).slice(-2);
            element.querySelector('.--hours').innerHTML = ('0' + Math.floor( (total / (1000 * 60 * 60)) % 24 )).slice(-2);
            element.querySelector('.--days').innerHTML = ('0' + Math.floor(total / (1000 * 60 * 60 * 24) )).slice(-2);
        },

        updateCountdown(index) {
            this.remainingTime(this.countdowns[index].element);
        },

        initSwiper() {
            this.galleryThumbs = new Swiper('.thumbslider__thumbs-container--slider', {
                spaceBetween: 10,
                slidesPerView: 3,
                loop: true,
                freeMode: false,
                centeredSlides: true,
                loopedSlides: this.totalSlides, // looped slides should be the same
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
            });

            this.galleryTop = new Swiper('.thumbslider__top-container', {
                spaceBetween: 0,
                loop: true,
                loopedSlides: this.totalSlides, // looped slides should  be the same
                autoplay: {
                    delay: 5000,
                },
                thumbs: {
                    swiper: this.galleryThumbs,
                },
            });

            this.galleryTop.on('slideChange', () => {
                this.currentSlide = this.galleryTop.realIndex;
            });
        },

        slideTo(slide) {
            this.galleryTop.slideTo(slide);
        },
    },

    beforeDestroy() {
        clearInterval(this.timeinterval);
    }
});
