import Vue from 'vue';
import Errors from '../classes/errors';

Vue.component('newsletter-form-component', {
    props: {
        siteHandle: {
            value: String
        }
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            formCompleted: false,
            submitting: false,

            dropdownCountry: null,
            countryHandle: null,
            dropdownHeardFrom: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        if (this.siteHandle !== 'default') {
            this.countryHandle = this.siteHandle;
        }
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        scrollToFirstError() {
            Vue.nextTick(() => {
                let firstError = document.querySelector('.form-label--error');
                zenscroll.center(firstError);
            });
        },

        scrollToTop() {
            Vue.nextTick(() => {
                zenscroll.toY(0);
            });
        },

        onSubmit(event) {
            const form = event.currentTarget;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            axios.post('/', new FormData(form))
            .then((response) => {
                this.submitting = false;
                const result = response.data;

                if (result.data.errors) {
                    this.errors.clear();
                    this.errors.record(result.data.errors);

                    this.scrollToFirstError();
                    return;
                }

                // Success
                // this.formCompleted = true;
                this.errors.clear();

                this.sendGTMData();

                window.location = this.$refs.redirectUrl.value;
                // this.scrollToTop();
            })
            .catch((error) => {
                this.submitting = false;
                console.log(error.response);
            });
        },

        sendGTMData() {

            const country = (this.siteHandle == 'default' ? 'mgMotorsEurope' : this.siteHandle);

            if (Vue.config.devtools == false && typeof fbq === 'function') { // check if you are on production-enviroment
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                window.dataLayer.push ({
                    'event' : 'newsletters',
                    'model': ' ',
                    'edition': ' ',
                    'page_path': window.location.href,
                    'country': `${country} - ${this.$root.languageCode}`,
                });
            }
        },

        setDropdownCountry(event) {
            this.dropdownCountry = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.countryHandle = value
        },

        toggleDropdown(event) {
            let element = event.currentTarget;

            while (element = element.parentElement) {
                if (element.classList.contains('dropdown')) {
                    element.classList.toggle('dropdown--active');
                }
            }
        },
        
        setDropdownHeardFrom(event) {
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.dropdownHeardFrom= value
        },
    },
});
