import Vue from 'vue';
import _orderBy from 'lodash/orderBy';
import Errors from '../classes/errors';

Vue.component('promo-form-component', {
    props: {
        siteHandle: {
            value: String
        },
        stores: {
            value: Object
        },
        brandstoreResultAmount: {
            value: Object
        },
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            formCompleted: false,
            submitting: false,
            requestType: 'private',
            requestIndex: 0,
            countryHandle: '',
            selected: '',
            selectedBrandStore: '',

            dropdownCountry: null,
            dropdownSubject: null,
            dropdownStore: null,

            // Dealer search (zip code vars)
            searchBrandstore: '',
            zipCoords: null,
            searchQueryIsDirty: false,
            nearbyProximityStores: null,
            // Error vars for zip api request
            needMoreInput: false,
            apiRequestLoading: false,
            countryValue: {
                'mgMotorsNetherlands': 'NL',
                'mgMotorsBelgiumNl': 'BE',
                'mgMotorsBelgiumFr': 'BE',
                'mgMotorsAustria': 'AT',
                'mgMotorsDanmark': 'DK',
                'mgMotorsGermany': 'DE',
                'mgMotorsSpain': 'ES',
                'mgMotorsFrance': 'FR',
                'mgMotorsIceland': 'IS',
                'mgMotorsItaly': 'IT',
                'mgMotorsLuxembourgFr': 'LU',
                'mgMotorsNorway': 'NO',
                'mgMotorsPortugal': 'PT',
                'mgMotorsSweden': 'SE',
            },
        };
    },

    computed: {
        moreThenTwoStores: function () {
            if (!this.countryHandle) {
                return false;
            }

            return Object.keys(this.stores[this.countryHandle]).length > 2;
        },

        hasBrandStores: function () {
            if (!this.countryHandle) {
                return false;
            }

            return Object.keys(this.stores[this.countryHandle]).length > 0;
        },
        orderedStores: function () {
            return _orderBy(this.stores[this.countryHandle], 'title')
        }
    },

    watch: {
        searchBrandstore() {
            if (this.searchBrandstore.length < 4) {
                this.needMoreInput = true;
                this.nearbyProximityStores = null;
                return;
            }

            if (this.selectedBrandStore) {
                if (this.selectedBrandStore.title === this.searchBrandstore) {
                    return;
                }
            }

            this.selectedBrandStore = null;
            this.needMoreInput = false;
            this.searchByZipGoogleApi();
        },

        countryHandle() {
            this.showPlanNl = false;
            // if (this.countryHandle == 'mgMotorsNetherlands') {
            //     this.showPlanNl = true;
            //     this.dropdownCar = null;
            // }
            this.selectedBrandStore = null;
            this.dropdownStore = null;
        },

    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        if (this.siteHandle !== 'default') {
            this.countryHandle = this.siteHandle;
        }

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        showDropdown(key) {
            if (!this.hasBrandStores) {
                return false;
            }

            if (!this.countryHandle) {
                return false;
            }

            //In this new design, it's always a dropdown
            // if (!this.moreThenTwoStores) {
            //     return false;
            // }

            if (this.countryHandle == key) {
                return true;
            }
        },

        showRadioButtons(key) {
            if (!this.hasBrandStores) {
                return false;
            }

            if (!this.countryHandle) {
                return false;
            }

            if (this.moreThenTwoStores) {
                return false;
            }

            if (this.countryHandle == key) {
                return true;
            }
        },
        scrollToFirstError() {
            Vue.nextTick(() => {
                let firstError = document.querySelector('.form-label--error');
                zenscroll.center(firstError);
            });
        },

        scrollToTop() {
            Vue.nextTick(() => {
                zenscroll.toY(0);
            });
        },

        onSubmit(event) {
            const form = event.currentTarget;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            axios
                .post("/", new FormData(form))
                .then(response => {
                    const result = response.data;

                    if (result.data.errors) {
                        this.errors.clear();
                        this.errors.record(result.data.errors);

                        this.scrollToFirstError();
                        this.submitting = false;
                        return;
                    }

                    // Success
                    this.formCompleted = true;
                    this.errors.clear();

                    window.location = result.data.redirectUrl;
                    this.scrollToTop();
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error);
                });
        },

        toggleDropdown(event) {
            let element = event.currentTarget;

            while (element = element.parentElement) {
                if (element.classList.contains('dropdown')) {
                    element.classList.toggle('dropdown--active');
                }
            }
        },

        setDropdownCountry(event) {
            this.dropdownCountry = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.countryHandle = value
        },

        setDropdownSubject(event) {
            this.dropdownSubject = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.selected = value;
        },

        setDropdownStore(event) {
            this.dropdownStore = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.selectedBrandStore = value
        },
    },
});
