import Vue from 'vue';

Vue.component('model-dropdown-component', {
    props: ['defaultValue', 'pixelId'],

    data() {
        return {
            selectedDropdownValue: '',
            dropdownValue: null,
            dropdownImage: '',
        };
    },

    watch: {
        selectedDropdownValue(value) {
            if (typeof p161 === 'object' && this.pixelId) {
                p161.track(this.pixelId, {customer_extra: value});
                if (typeof window.trackQueue === 'function') {
                    window.trackQueue(p161);
                }
            }
        }
    },

    mounted() {
        if(this.defaultValue) {
            const defaultValueTarget = document.getElementById(this.defaultValue);
            const defaultValueTargetValue = defaultValueTarget.dataset.value;
            const defaultValueTargetText = defaultValueTarget.dataset.text;
            const defaultValueTargetImage = defaultValueTarget.dataset.image;
            
            this.dropdownValue = defaultValueTargetText;
            this.selectedDropdownValue = defaultValueTargetValue;
            this.dropdownImage = defaultValueTargetImage;
        }
    },

    methods: {
        setDropdownValue(event) {
            this.dropdownValue = event.currentTarget.dataset.text;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.selectedDropdownValue = value;
            this.dropdownImage = event.currentTarget.dataset.image;

            this.$parent.model = value;
            this.$parent.resetCountryDropdown();
        },
        
        toggleDropdown(event) {
            let element = event.currentTarget;

            while (element = element.parentElement) {
                if (element.classList.contains('dropdown')) {
                    element.classList.toggle('dropdown--active');
                }
            }
        },
    },
});
