import Vue from "vue";

Vue.component("owner-manual-search-component", {
    props: {
        manuals: Array
    },

    data() {
        return {
            searchQuery: "",
            searchResults: null,
            vinError: false,
            awaitingSearch: false
        };
    },

    methods: {
        throttledSearch() {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.search();
                    this.awaitingSearch = false;
                }, 500);
            }
            this.awaitingSearch = true;
        },
        search() {
            this.vinError = false;

            if (this.searchQuery.length !== 17 && this.searchQuery.length > 0) {
                this.vinError = true;
                return;
            }

            const searchString =
                this.searchQuery.slice(0, 8) +
                this.searchQuery[9] +
                this.searchQuery[10];

            let results = [];
            this.manuals.forEach(current => {
                current.code.forEach(code => {
                    // if the code contains a * shorten code and searchstring (non ZS models, like EHS: LSJA2439*N)
                    if (code[8] === "*") {
                        const shortCode =
                            code.slice(0, 8) + code.slice(9, code.length);

                        const shortSearchString =
                            searchString.slice(0, 8) +
                            searchString.slice(9, searchString.length);

                        if (shortCode === shortSearchString) {
                            current.manuals.forEach(manual => {
                                results.push(manual);
                            });
                        }

                        return;
                    }

                    // otherwise use code and searchstring as is (ZS models: LSJW7409KZ)
                    if (code === searchString) {
                        current.manuals.forEach(manual => {
                            results.push(manual);
                        });
                    }

                    return;
                });
            });

            this.searchResults = results;
        }
    }
});
