import Vue from 'vue';

Vue.component('input-switch', {

    data() {
        return {
            position: 'first',
        };
    },

    mounted() {
        
    },

    methods: {
        moveSlide(position) {
            if (position === 'first') {
                this.position = 'first'
            }

            if (position === 'second') {
                this.position = 'second'
            }

            if (position === 'third') {
                this.position = 'third'
            }
            
        },
    },
});
