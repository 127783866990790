import Vue from 'vue';
import Swiper from 'swiper';

Vue.component('youtube-slider', {

    data() {
        return {
            sectionIndex: 0,
            activeSlide: 1,
            swiper: null,
        };
    },

    mounted() {
        this.initSwiper();
    },

    methods: {

        initSwiper() {
            const slider = this.$el.querySelector('.youtube-slider__slider');
            this.swiper = new Swiper(slider, {
                slidesPerView: 1,
                spaceBetween: 0,
                loop: true,
                centeredSlides: true,
                pagination: {
                    el: this.$el.querySelector('.swiper-pagination'),
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '"><span></span></span>';
                    }
                },
                navigation: {
                    nextEl: '.page-slider-next',
                    prevEl: '.page-slider-prev',
                }
            })

            this.swiper.on('slideChange', () => {
                this.activeSlide = this.swiper.activeIndex
            })
        },

    },
});
