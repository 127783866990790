import Vue from "vue";
import _throttle from "lodash/throttle";
import _groupBy from "lodash/groupBy";
import MarkerClusterer from "@googlemaps/markerclustererplus";

Vue.component("brand-stores-map-component", {
    props: {
        mapCenterLat: Number,
        mapCenterLong: Number,
        mapZoomFactor: Number,
        siteHandle: String,
        europeMap: Boolean,
        storeSearchError: String,
        siteUrl: String
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            storeInfoShowed: false,
            selectedStore: [],
            stores: [],
            map: null,
            city: "",
            geocoder: null,
            storeFilter: "dealer",
            autocomplete: null,
            markerCluster: null,
            markers: null,
            searchError: null,
            zoom: null,
            loadedJSON: this.siteHandle,
            errors: null,

            searchBrandstore: null,
            zipCoords: null,
            searchQueryIsDirty: false,
            nearbyProximityStores: null,
            storesWithDistance: null,
            // Error vars for zip api request
            needMoreInput: false,
            apiRequestLoading: false,
            countryValue: {
                mgMotorsNetherlands: "NL",
                mgMotorsBelgiumNl: "BE",
                mgMotorsBelgiumFr: "BE",
                mgMotorsAustria: "AT",
                mgMotorsDanmark: "DK",
                mgMotorsGermany: "DE",
                mgMotorsSpain: "ES",
                mgMotorsFrance: ["FR", "MQ", "GF", "GP"],
                mgMotorsIceland: "IS",
                mgMotorsItaly: "IT",
                mgMotorsLuxembourgFr: "LU",
                mgMotorsNorway: "NO",
                mgMotorsPortugal: "PT",
                mgMotorsSweden: "SE"
            },
            filteredStores: null
        };
    },

    watch: {
        searchBrandstore() {
            if (this.searchBrandstore && this.searchBrandstore.length < 4) {
                this.needMoreInput = true;
                this.nearbyProximityStores = null;
                return;
            }

            if (this.selectedBrandStore) {
                if (this.selectedBrandStore.title === this.searchBrandstore) {
                    return;
                }
            }

            this.selectedBrandStore = null;
            this.needMoreInput = false;
            this.searchByZipGoogleApi();
        },

        city: function(newVal, oldVal) {
            if (newVal === undefined) {
                this.city = oldVal;
            }
        },
        zoom: function(currentZoom, prevZoom) {
            if (!prevZoom) {
                return;
            }

            if (
                currentZoom < 6 &&
                !this.europeMap &&
                this.siteHandle === "mgMotorsFrance"
            ) {
                if (this.loadedJSON === "default") {
                    return;
                }

                this.markerCluster.clearMarkers();
                this.loadJson("default");
                this.fetchEvents("default");
                this.loadedJSON = "default";
            } else if (currentZoom >= 6 && !this.europeMap) {
                if (this.loadedJSON === this.siteHandle) {
                    return;
                }

                this.markerCluster.clearMarkers();
                this.loadJson();
                this.fetchEvents("default");
                this.loadedJSON = this.siteHandle;
            }
        }
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        Vue.nextTick(() => {
            this.city = null;
            this.searchBrandstore = null;
        });
        this.init();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        init() {
            EventBus.$once("google-maps-ready", this.initMap);

            this.fetchEvents();
            // this.injectGoogleMaps();
            this.intersect();
        },

        searchByZipGoogleApi: _throttle(function() {
            this.makeApiRequest();
        }, 1000),

        makeApiRequest() {
            this.apiRequestLoading = true;

            this.geocoder.geocode(
                {
                    // don't restrict for FR, breaks when passing array
                    ...(this.siteHandle !== "mgMotorsFrance" && {
                        componentRestrictions: {
                            country: this.countryValue[this.siteHandle]
                        }
                    }),
                    address: encodeURI(this.searchBrandstore)
                },
                (results, status) => {
                    if (status === "OK") {
                        if (results[0]) {
                            if (results[0]) {
                                this.searchError = "";
                                this.apiRequestLoading = false;
                                this.zipCoords = {
                                    lat: results[0].geometry.location.lat(),
                                    lng: results[0].geometry.location.lng()
                                };

                                if (!this.zipCoords) {
                                    return;
                                }
                                var storesData = null;
                                if (this.storeFilter === "service") {
                                    storesData = this.filteredStores
                                        .serviceStore;
                                } else {
                                    const concat = (...arrays) =>
                                        [].concat(
                                            ...arrays.filter(Array.isArray)
                                        );
                                    storesData = concat(
                                        this.filteredStores.none,
                                        this.filteredStores.testDriveStore
                                    );
                                }
                                this.getNearbyBrandstores(
                                    this.zipCoords,
                                    storesData
                                );
                            }
                        }
                        return null;
                    } else {
                        console.error(
                            "Geocode was not successful for the following reason: " +
                                status
                        );
                        this.searchError = this.storeSearchError; //'Couldn\'t retrieve your location, please try again';
                    }
                }
            );
        },

        getNearbyBrandstores(userLocation, brandstores) {
            this.storesWithDistance = Object.values(brandstores).map(store => {
                const d = this.getDistance(userLocation, store);

                return {
                    ...store,
                    distance: d
                };
            });

            this.nearbyProximityStores = this.storesWithDistance
                .sort((a, b) => {
                    if (a.distance < b.distance) {
                        return -1;
                    }

                    if (a.distance > b.distance) {
                        return 1;
                    }

                    return 0;
                })
                .slice(0, 1);

            if (this.nearbyProximityStores != null) {
                var pos = {
                    lat: parseFloat(this.nearbyProximityStores[0].lat),
                    lng: parseFloat(this.nearbyProximityStores[0].lng)
                };

                this.map.setCenter(pos);
                this.map.setZoom(12);

                this.storeInfoShowed = false;
                this.selectedStore = this.nearbyProximityStores[0];

                this.showStoreInfo(this.nearbyProximityStores[0].id);
            }
        },

        getDistance(userLocation, brandstore) {
            // Bron: https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
            var radius = 6371.071; // Radius of the Earth in KM

            const radiusLat1 = userLocation.lat * (Math.PI / 180); // Convert degrees to radians
            const radiusLat2 = brandstore.lat * (Math.PI / 180); // Convert degrees to radians
            const differenceLat = radiusLat2 - radiusLat1; // Radian difference (latitudes)
            const differenceLng =
                (brandstore.lng - userLocation.lng) * (Math.PI / 180); // Radian difference (longitudes)

            const distance =
                2 *
                radius *
                Math.asin(
                    Math.sqrt(
                        Math.sin(differenceLat / 2) *
                            Math.sin(differenceLat / 2) +
                            Math.cos(radiusLat1) *
                                Math.cos(radiusLat2) *
                                Math.sin(differenceLng / 2) *
                                Math.sin(differenceLng / 2)
                    )
                );

            return distance;
        },

        /**
         * Initialize the Google Map
         *
         * @return {Void}
         */
        initMap() {
            const mapStyle = [
                {
                    featureType: "all",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "all",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "all",
                    elementType: "labels.text.fill",
                    stylers: [
                        {
                            saturation: 36
                        },
                        {
                            color: "#000000"
                        },
                        {
                            lightness: 40
                        }
                    ]
                },
                {
                    featureType: "all",
                    elementType: "labels.text.stroke",
                    stylers: [
                        {
                            visibility: "on"
                        },
                        {
                            color: "#000000"
                        },
                        {
                            lightness: 16
                        }
                    ]
                },
                {
                    featureType: "all",
                    elementType: "labels.icon",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#E8E8E8"
                        }
                    ]
                },
                {
                    featureType: "administrative",
                    elementType: "geometry.stroke",
                    stylers: [
                        {
                            color: "#E8E8E8"
                        },
                        {
                            lightness: 17
                        },
                        {
                            weight: 1.2
                        }
                    ]
                },
                {
                    featureType: "administrative",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "administrative",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "administrative.country",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "simplified"
                        }
                    ]
                },
                {
                    featureType: "administrative.country",
                    elementType: "geometry",
                    stylers: [
                        {
                            visibility: "simplified"
                        }
                    ]
                },
                {
                    featureType: "administrative.country",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "simplified"
                        }
                    ]
                },
                {
                    featureType: "administrative.province",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "administrative.locality",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "simplified"
                        },
                        {
                            saturation: "-100"
                        },
                        {
                            lightness: "30"
                        }
                    ]
                },
                {
                    featureType: "administrative.neighborhood",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "administrative.land_parcel",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "landscape",
                    elementType: "all",
                    stylers: [
                        {
                            visibility: "simplified"
                        },
                        {
                            gamma: "0.00"
                        },
                        {
                            lightness: "74"
                        }
                    ]
                },
                {
                    featureType: "landscape",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#E8E8E8"
                        },
                        {
                            lightness: 20
                        }
                    ]
                },
                {
                    featureType: "landscape",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "landscape",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "landscape.man_made",
                    elementType: "all",
                    stylers: [
                        {
                            lightness: "3"
                        }
                    ]
                },
                // {
                //     featureType: "poi",
                //     elementType: "all",
                //     stylers: [
                //         {
                //             visibility: "off"
                //         }
                //     ]
                // },
                {
                    featureType: "poi",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#E8E8E8"
                        },
                        {
                            lightness: 21
                        }
                    ]
                },
                {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "poi",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "poi.park",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#CAEBC9"
                        },
                        {
                            lightness: 21
                        }
                    ]
                },
                {
                    featureType: "road",
                    elementType: "geometry",
                    stylers: [
                        {
                            visibility: "simplified"
                        },
                        {
                            color: "#FFFFFF"
                        },
                        {
                            lightness: "-61"
                        }
                    ]
                },
                {
                    featureType: "road",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "road",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.fill",
                    stylers: [
                        {
                            color: "#D1D1D1"
                        },
                        {
                            lightness: 17
                        }
                    ]
                },
                {
                    featureType: "road.highway",
                    elementType: "geometry.stroke",
                    stylers: [
                        {
                            color: "#D1D1D1"
                        },
                        {
                            lightness: 29
                        },
                        {
                            weight: 0.2
                        }
                    ]
                },
                {
                    featureType: "road.arterial",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#FFFFFF"
                        },
                        {
                            lightness: 18
                        }
                    ]
                },
                {
                    featureType: "road.local",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#FFFFFF"
                        },
                        {
                            lightness: 16
                        }
                    ]
                },
                // {
                //     featureType: "transit",
                //     elementType: "geometry",
                //     stylers: [
                //         {
                //             color: "#2a2727"
                //         },
                //         {
                //             lightness: "-61"
                //         },
                //         {
                //             saturation: "-100"
                //         }
                //     ]
                // },
                {
                    featureType: "transit",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "water",
                    elementType: "geometry",
                    stylers: [
                        {
                            color: "#96D1D8"
                        },
                        {
                            lightness: 17
                        }
                    ]
                },
                {
                    featureType: "water",
                    elementType: "labels",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                },
                {
                    featureType: "water",
                    elementType: "labels.text",
                    stylers: [
                        {
                            visibility: "off"
                        }
                    ]
                }
            ];

            // If map must show the europe map, set small zoom
            this.zoom = this.europeMap ? 4 : this.mapZoomFactor;

            // If map must show the europe map, set lat and long so europe is visible
            const lat = this.europeMap ? 52.370216 : this.mapCenterLat;
            const long = this.europeMap ? 4.895168 : this.mapCenterLong;

            this.map = new google.maps.Map(document.getElementById("gmap"), {
                zoom: this.zoom,
                center: { lat: lat, lng: long },
                styles: mapStyle,
                scrollwheel: false,
                disableDefaultUI: true
            });

            this.loadJson();

            // Adding Google maps Geocoder
            this.geocoder = new google.maps.Geocoder();

            // Adding Google maps Autocomplete (places API)
            this.addAutocomplete();

            const infoWindow = new google.maps.InfoWindow();
            infoWindow.setOptions({
                pixelOffset: new google.maps.Size(0, -30)
            });

            // Try HTML5 geolocation. navigator.geolocation.getCurrentPosition(success, error);
            this.addGeolocation();
        },

        loadJson(value) {
            // Original this.siteHandle is used in the GET request. Hardcoded so that Germany has the same behaviour as Europe does.
            var siteHandle = this.europeMap ? "default" : this.siteHandle;

            if (value != undefined && value == "default") {
                siteHandle = "default";
            }
            // this.map.data.loadGeoJson("/data/map.json?site=" + this.siteHandle);
            this.map.data.loadGeoJson(
                "/data/map.json?site=" + siteHandle,
                null,
                features => {
                    this.markers = features.map(feature => {
                        const id = feature.getProperty("id");
                        const specialStoreType = feature.getProperty(
                            "specialStoreType"
                        );

                        var g = feature.getGeometry();

                        var iconurl = "/media/images/brandstore-pin.png";
                        if (
                            specialStoreType.value == "salesRepresentative" &&
                            this.siteHandle == "mgMotorsFrance"
                        ) {
                            iconurl =
                                "/media/images/marker-sales-representative.png";
                        }

                        var marker = new google.maps.Marker({
                            position: g.get(0),
                            icon: iconurl,
                            id: id,
                            type: specialStoreType.value
                        });

                        google.maps.event.addListener(marker, "click", evt => {
                            this.showStoreInfo(marker.id);
                        });

                        return marker;
                    });

                    this.markerCluster = new MarkerClusterer(
                        this.map,
                        this.markers,
                        {
                            imagePath: "/media/images/black-pin",
                            imageExtension: "png"
                        }
                    );

                    this.setFilter("dealer");
                }
            );

            // this.map.data.setMap(null)
            this.map.data.setStyle(feature => {
                return { icon: feature.getProperty("icon"), visible: false };
            });
        },

        addAutocomplete() {
            var input = document.getElementById("autocomplete");
            if (this.siteHandle == "default") {
                var options = {
                    types: ["(cities)"],
                    fields: ["formatted_address"]
                };
            } else {
                var options = {
                    types: ["(cities)"],
                    fields: ["formatted_address"],
                    //because we use 'Autocomplete' we can use an array (FR), note max length = 5
                    componentRestrictions: {
                        country: this.countryValue[this.siteHandle]
                    }
                };
            }
            this.autocomplete = new google.maps.places.Autocomplete(
                input,
                options
            );

            this.autocomplete.addListener("place_changed", this.onPlaceChanged);
        },

        addGeolocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        var pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };

                        this.map.setCenter(pos);
                        this.map.setZoom(8);
                    },
                    () => {
                        console.log(
                            "ERROR: Something went wrong, couldn't retrieve current position"
                        );
                    }
                );
            } else {
                // Browser doesn't support Geolocation
                console.log("Browser doesn't support Geolocation");
            }
        },

        onPlaceChanged() {
            const place = this.autocomplete.getPlace();

            if (place.formatted_address) {
                if (this.searchBrandstore == null) {
                    this.city = place.formatted_address;

                    this.searchError = null;
                    this.codeAddress();
                } else {
                    this.needMoreInput = false;
                    this.searchBrandstore = place.formatted_address;
                    this.searchError = null;
                }
            }
        },

        intersect() {
            var options = {
                rootMargin: "300px",
                threshold: 0
            };

            var map = document.getElementById("map");

            var observer = new IntersectionObserver((entries, observer) => {
                // Detect intersection https://calendar.perfplanet.com/2017/progressive-image-loading-using-intersection-observer-and-sqip/#comment-102838
                var isIntersecting =
                    typeof entries[0].isIntersecting === "boolean"
                        ? entries[0].isIntersecting
                        : entries[0].intersectionRatio > 0;
                if (isIntersecting) {
                    this.injectGoogleMaps();
                    observer.unobserve(map);
                }
            }, options);

            observer.observe(map);
        },

        /**
         * Injects the Google Maps scripts when it is not there yet.
         *
         * @return {Void}
         */
        injectGoogleMaps() {
            var tag = document.createElement("script");
            tag.setAttribute("type", "text/javascript");
            tag.defer = true;
            tag.async = true;
            tag.setAttribute(
                "src",
                "//maps.google.com/maps/api/js?key=AIzaSyBXLteX7ErcVvjvFLHB1_TMCzq55wVb5Bg&libraries=places&callback=mapsCallback"
            );
            (
                document.getElementsByTagName("head")[0] ||
                document.documentElement
            ).appendChild(tag);
        },

        /**
         * Fetches the events from the datasource.
         *
         * @return {Void}
         */
        fetchEvents(value) {
            // Original this.siteHandle is used in the GET request. Hardcoded so that Germany has the same behaviour as Europe does.
            var siteHandle = this.europeMap ? "default" : this.siteHandle;

            if (value != undefined && value == "default") {
                siteHandle = "default";
            }

            axios
                .get(`${this.siteUrl}/actions/mg-business-logic/brand-stores`)
                .then(response => {
                    this.stores = response.data;

                    this.filteredStores = _groupBy(
                        this.stores.data,
                        "specialStoreType.value"
                    );
                })
                .catch(error => {
                    console.log(error);
                });
        },

        /**
         * Show store info when map marker on map is clicked
         *
         * @return {Void}
         */
        showStoreInfo(clickedId) {
            if (this.storeInfoShowed) {
                if (clickedId == this.selectedStore.id) {
                    this.storeInfoShowed = false;
                    this.selectedStore = [];

                    return;
                }
            }

            const selectedStore = this.stores.data.find(
                store => store.id == clickedId
            );
            this.selectedStore = selectedStore;
            this.storeInfoShowed = true;

            if (Vue.config.devtools == false && typeof fbq === "function") {
                // check if you are on production-enviroment
                if (this.$root.handle == "mgMotorsFrance") {
                    gtag("event", "conversion", {
                        allow_custom_scripts: true,
                        send_to: "DC-10256326/mg-autos/fr_in003+unique"
                    }); //french
                }

                if (this.$root.handle == "mgMotorsNorway") {
                    gtag("event", "conversion", {
                        allow_custom_scripts: true,
                        send_to: "DC-10210913/retar0/no_re000+standard"
                    }); //norway
                }
            }
        },

        /**
         * Raised when zoom in button of the map is clicked.
         *
         * @param   {Event}
         * @returns {Void}
         */
        onMapZoomInClick(event) {
            this.map.setZoom(this.map.getZoom() + 1);
            this.zoom = this.map.getZoom();
        },

        /**
         * Raised when the zoom out button of the map is clicked.
         *
         * @param {Event} event
         * @returns {Void}
         */
        onMapZoomOutClick(event) {
            this.map.setZoom(this.map.getZoom() - 1);
            this.zoom = this.map.getZoom();
        },

        codeAddress() {
            this.geocoder.geocode(
                {
                    address: this.city,
                    // don't restrict for FR, breaks when passing array
                    ...(this.siteHandle !== "mgMotorsFrance" && {
                        componentRestrictions: {
                            country: this.countryValue[this.siteHandle]
                        }
                    })
                },
                (results, status) => {
                    if (status == "OK" && !this.nearbyProximityStores) {
                        this.map.setCenter(results[0].geometry.location);
                        this.map.setZoom(this.europeMap ? 8 : 10);
                        this.searchError = null;
                    } else {
                        this.searchError =
                            "Couldn't locate \"" +
                            this.city +
                            '", please try again';
                    }
                }
            );
        },

        setFilter(value) {
            this.storeFilter = value;

            this.markerCluster.markers_.map(e => {
                e.setVisible(false);
                if (
                    this.storeFilter === "service" &&
                    e.type == "serviceStore"
                ) {
                    e.setVisible(true);
                } else if (this.storeFilter !== "service") {
                    if (e.type == "serviceStore") {
                        e.setVisible(false);
                    } else {
                        e.setVisible(true);
                    }
                } else {
                    e.setVisible(false);
                }
            });
            this.markerCluster.setIgnoreHidden(true);
            this.markerCluster.repaint();

            // this.map.setZoom(8);
        },

        isNullOrEmpty(str) {
            return !str || !str.trim();
        },

        handlePermission() {
            navigator.permissions
                .query({ name: "geolocation" })
                .then(result => {
                    if (result.state == "granted") {
                        this.report(result.state);
                        // geoBtn.style.display = 'none';
                        this.addGeolocation();
                    } else if (result.state == "prompt") {
                        this.report(result.state);
                        // geoBtn.style.display = 'none';
                        navigator.geolocation.getCurrentPosition(
                            revealPosition,
                            positionDenied,
                            geoSettings
                        );
                        this.addGeolocation();
                    } else if (result.state == "denied") {
                        this.report(result.state);
                        // geoBtn.style.display = 'inline';
                        this.searchError =
                            "Access is denied to your current position, please change browser settings to use this function";
                    }
                    result.onchange = () => {
                        this.report(result.state);
                    };
                });
        },

        report(state) {
            console.log("Permission " + state);
        },

        datalayerMailto() {
            if (this.siteHandle == "mgMotorsFrance") {
                dataLayer.push({
                    event: "potentialLead",
                    eventCategory: "Potential Leads",
                    eventAction: "Dealer mailto",
                    eventLabel: this.selectedStore.title
                });
            }
        },

        datalayerPhone() {
            if (this.siteHandle == "mgMotorsFrance") {
                dataLayer.push({
                    event: "potentialLead",
                    eventCategory: "Potential Leads",
                    eventAction: "Dealer telephone",
                    eventLabel: this.selectedStore.title
                });
            }
        },

        datalayerRoute() {
            if (this.siteHandle == "mgMotorsFrance") {
                dataLayer.push({
                    event: "potentialLead",
                    eventCategory: "Potential Leads",
                    eventAction: "Dealer route",
                    eventLabel: this.selectedStore.title
                });
            }
        }
    }
});
