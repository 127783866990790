import Vue from 'vue';

Vue.component('selling-points-component', {
    props: {
        values: Object,
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            currentIndex: 0,
            interval: 5,

            instance: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.play();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        play() {
            this.interval = setInterval(() => {
                let next = this.currentIndex + 1;
                if (next > (this.values.positive.length - 1)) {
                    next = 0;
                }
                
                this.currentIndex = next;
            }, this.interval * 1000);
        },

        stop() {
            clearInterval(this.interval);
        }
    },

    beforeDestroy() {
        this.stop();
    }
});
