import Vue from 'vue';
import Errors from "../classes/errors";

Vue.component("become-partner-component", {
    props: {
        distributors: {
            value: Array
        },
        siteHandle: {
            value: String
        }
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            formCompleted: false,
            submitting: false,
            countryHandle: "",

            dropdownCountry: null,
            dropdownActivityLocation: null,
        };
    },

    computed: {
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        //

        if (this.siteHandle !== "default") {
            this.countryHandle = this.siteHandle;
        }
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        scrollToFirstError() {
            Vue.nextTick(() => {
                let firstError = document.querySelector(".form-label--error");
                zenscroll.center(firstError);
            });
        },

        scrollToTop() {
            Vue.nextTick(() => {
                zenscroll.toY(0);
            });
        },

        onSubmit(event) {
            const form = event.currentTarget;

            if (this.submitting) {
                return;
            }

            this.submitting = true;

            axios
                .post("/", new FormData(form))
                .then(response => {
                    const result = response.data;
                    
                    if (result.data.errors) {
                        this.errors.clear();
                        this.errors.record(result.data.errors);
                        
                        this.submitting = false;
                        this.scrollToFirstError();
                        return;
                    }

                    // Success
                    this.formCompleted = true;
                    this.errors.clear();

                    // this.scrollToTop();

                    window.location = result.data.redirectUrl;
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error.response);
                });
        },

        toggleDropdown(event) {
            let element = event.currentTarget;

            while (element = element.parentElement) {
                if (element.classList.contains('dropdown')) {
                    element.classList.toggle('dropdown--active');
                }
            }
        },

        setDropdownCountry(event) {
            this[event.target.parentElement.dataset.dropdownName] = event.target.innerHTML;
            this.toggleDropdown(event);

            const value = event.currentTarget.dataset.value;
            this.countryHandle = value
        },

        setDropdownActivityLocation(event) {
            this[event.target.parentElement.dataset.dropdownName] = event.target.innerHTML;
            this.toggleDropdown(event);

            // const value = event.currentTarget.dataset.value;
            // this.countryHandle = value
        },
    }
});
