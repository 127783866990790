import Vue from 'vue';
import Errors from "../classes/errors";
// import _ from 'lodash';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

Vue.component("configurator-form", {
    name: "configurator-form",
    props: {
        locale: {
            type: String
        },
        siteHandle: {
            type: String
        },
        commerceCountries: {
            type: Object
        },
        adyenGatewayId: {
            type: String
        },
        adyenEnvironment: {
            type: String
        },
        adyenMerchantId: {
            type: String
        },
    },

    data() {
        return {
            errors: new Errors(),
            submitting: false,

            configuration: {},
            requestType: 'private',
            color: null,
            edition: null,
            version: null,
            editionOptions: [],
            paymentOption: null,
            promotions: [],
            totalPrice: '',
            formattedTotalPrice: '',
            price: '',
            formattedPrice: '',
            priceNa: false,
            directPaymentEnabled: false,
            adyenCheckout: null,
            adyenCardFields: null,
            creditCardValues: {
                'cardnumber': '',
                'expiryMonth': '',
                'expiryYear': '',
                'cvv': '',
            },
            selectedBrandStore: null,

            // Set a valid order ID (as taken from CMS) here to test PDF export for mails (eg 1627316).
            // You also have to set the $testPdfExport property to true in the RequestOfferController for this to work.
            // You can then trigger the download by submitting the configurator payment form (you don't have to fill it in, it will use the order data).
            // Don't forget to put both properties back when you're done testing!
            testPdfExport: null,

            dropdownHeardFrom: null,
            heardFrom: null,
        }
    },

    computed: {
        configurationJson() {
            return JSON.stringify(this.configuration);
        }
    },

    watch: {

        /**
         * Load Adyen credit card package if payment is enabled.
         */
        directPaymentEnabled(value) {
            if (value && !this.adyenCardFields) {
                this.$nextTick(() => {
                    this.adyenCardFields = this.adyenCheckout.create('dropin', {
                        openFirstPaymentMethod: true,
                    }).mount('#credit-card-payment');
                });
            }
        }
    },

    created() {
        // Listen to configurator-set-direct-payment event to update directPaymentEnabled.
        EventBus.$on('configurator-set-direct-payment', (directPaymentEnabled) => {
            this.directPaymentEnabled = directPaymentEnabled ? 1 : 0;
        });

        // Listen to configuration completed event to update configuration result.
        EventBus.$on('configurator-completed', (configuration) => {
            this.configuration = configuration;
        });

        // Update request type setting on event.
        EventBus.$on('configurator-set-request-type', (requestType) => {
            this.requestType = requestType;
        });

        // Listen for color change.
        EventBus.$on('configurator-set-color', (color) => {
            this.color = color;
        });

        // Listen for edition change.
        EventBus.$on('configurator-set-edition', (edition) => {
            this.edition = edition;
        });

        // Update version on version selection change.
        EventBus.$on('configurator-set-version', (version) => {
            this.version = version;
        });

        // Add listener to update edition options when they change.
        EventBus.$on('configurator-set-edition-options', (options) => {

            // Loop options to fill editionOptions with the ones that have been selected.
            this.editionOptions = [];
            for (let option in options) {
                if (options[option].selected) {
                    this.editionOptions.push(options[option]);
                }
            }
        });

        // Listen for edition change.
        EventBus.$on('configurator-set-payment-option', (paymentOption) => {
            this.paymentOption = paymentOption;
        });

        // Add listener to update promotions when they change.
        EventBus.$on('configurator-set-promotions', (promotions) => {

            // Loop promotions to fill editionOptions with the ones that have been selected.
            this.promotions = [];
            for (let promotion in promotions) {
                if (promotions[promotion].selected && promotions[promotion].priceAddition < 0) {
                    this.promotions.push(promotions[promotion]);
                }
            }
        });

        // Listen for prica n/a.
        EventBus.$on('configurator-price-na', () => {
            this.priceNa = true;
            this.totalPrice = '';
            this.formattedTotalPrice = '';
            this.price = '';
            this.formattedPrice = '';
        });

        // Listen for total-price change.
        EventBus.$on('configurator-total-price-update', (totalPrice, formattedTotalPrice) => {
            this.priceNa = false;
            this.totalPrice = totalPrice;
            this.formattedTotalPrice = '';
            if (this.paymentOption.field_paymentOption_priceTextFormBlock) {
                this.formattedTotalPrice = this.paymentOption.field_paymentOption_priceTextFormBlock.replace('{price}', totalPrice);
            }
        });

        // Listen for total-price change.
        EventBus.$on('configurator-price-update', (price, formattedPrice) => {
            this.price = price;
            this.formattedPrice = '';
            if (this.paymentOption.field_paymentOption_priceTextFormBlock) {
                this.formattedPrice = this.paymentOption.field_paymentOption_priceTextFormBlock.replace('{price}', price);
            }
        });
    },

    mounted() {
        // Load Adyen component.
        this.adyenCheckout = new AdyenCheckout({
            paymentMethodsResponse: JSON.parse('{"paymentMethods":[{"brands":["mc","visa"],"details":[{"key":"encryptedCardNumber","type":"cardToken"},{"key":"encryptedSecurityCode","type":"cardToken"},{"key":"encryptedExpiryMonth","type":"cardToken"},{"key":"encryptedExpiryYear","type":"cardToken"},{"key":"holderName","optional":true,"type":"text"}],"name":"Kreditkarte","type":"scheme"}]}'),
            locale: this.locale,
            environment: this.adyenEnvironment,
            clientKey: this.adyenMerchantId,
            paymentMethodsConfiguration: {
                card: {
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: false,
                    hideCVC: false,
                    name: 'Credit or debit card',
                },
            },

            // Handle form submit.
            onSubmit: (state, dropin) => {
                if (this.submitting) {
                    return;
                }

                this.errors.clear();

                this.creditCardValues = {
                    'cardnumber': state.data.paymentMethod.encryptedCardNumber,
                    'expiryMonth': state.data.paymentMethod.encryptedExpiryMonth,
                    'expiryYear': state.data.paymentMethod.encryptedExpiryYear,
                    'cvv': state.data.paymentMethod.encryptedSecurityCode,
                    'holderName': state.data.paymentMethod.holderName,
                    'riskData': state.data.riskData,
                    'browserInfo': state.data.browserInfo,
                };

                this.onSubmit(null);
            },

            onAdditionalDetails: (state, dropin) => {
                this.submitting = true;

                // Create payment data form.
                const formData = new FormData(this.$refs.form);
                let paymentData = new FormData();
                paymentData.append('action', 'mg-business-logic/request-offer/process-payment');
                paymentData.append(window.csrfTokenName, window.csrfTokenValue);
                paymentData.append('siteId', formData.get('siteId'));
                paymentData.append('paymentDetails', JSON.stringify(state.data));

                axios
                .post("/", paymentData)
                .then((response) => {

                    // Fetch result.
                    const result = response.data;

                    // Handle form errors.
                    if (result.data.errors) {
                        this.errors.clear();
                        this.errors.record(result.data.errors);
                        this.submitting = false;
                        this.scrollToFirstError();
                        this.adyenCardFields.setStatus('ready');
                        return;
                    }

                    if (result.data.action) {
                        this.submitting = false;
                        this.adyenCardFields.handleAction(result.data.action);
                        return;
                    }

                    // Track form submission.
                    this.trackFormSubmit(formData);

                    // Redirect to the thank you page.
                    window.location = result.data.redirectUrl;
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error);
                });
            },
        });
    },

    methods: {

        /**
         * Emit event to switch back to the configurator screen.
         * 
         * @returns {Void}
         */
        returnToConfiguratorEdit() {
            EventBus.$emit('configurator-back-to-the-studio');
        },

        /**
         * Use zenscroll to scroll to the first element that has a form error.
         * 
         * @returns {Void}
         */
        scrollToFirstError() {
            Vue.nextTick(() => {
                let firstError = document.querySelector(".form-label--error");
                zenscroll.center(firstError);
            });
        },

        /**
         * Use zenscroll to scroll all the way up to the top.
         * 
         * @returns {Void}
         */
        scrollToTop() {
            Vue.nextTick(() => {
                zenscroll.toY(0);
            });
        },

        /**
         * Handles form submission.
         * 
         * @param {SubmitEvent} event 
         * @returns {Void}
         */
        onSubmit(event) {
            const form = event.currentTarget;

            // Don't submit twice.
            if (this.submitting) {
                return;
            }
            this.submitting = true;

            grecaptcha.ready(() => {
                grecaptcha.execute('6LeCf8AZAAAAAEUasZwzVxRkrUX8bW-WQxTKqDYo', {action: 'submit'}).then((token) => {
                    
                    // Add your logic to submit to your backend server here.

                    form.querySelector('#recaptchaTokenField').value = token

                // Set form data.
                const formData = new FormData(this.$refs.form);

                // Skip validation when testing PDF.
                if (this.directPaymentEnabled && this.testPdfExport) {
                    this.handleDirectPayment(formData);
                    return;
                }

                // Post form to craft.
                axios
                    .post("/", formData)
                    .then(response => {

                        // Fetch result.
                        const result = response.data;

                        // Handle form errors.
                        if (result.data.errors) {
                            this.errors.clear();
                            this.errors.record(result.data.errors);
                            this.submitting = false;
                            this.scrollToFirstError();
                            return;
                        }

                        // Handle direct payment.
                        if (this.directPaymentEnabled) {
                            this.handleDirectPayment(formData);
                            return;
                        }

                        this.sendGTMData(formData);

                        // Track form submission.
                        this.trackFormSubmit(formData);

                        // Redirect to the thank you page.
                        window.location = result.data.redirectUrl;
                    })
                    .catch(error => {

                        // Free form and log response on error.
                        this.submitting = false;
                        console.error(error.response);
                    });
                });
            });
        },

        sendGTMData(formData) {
            const country = (this.siteHandle == 'default' ? 'mgMotorsEurope' : this.siteHandle);
            const model = formData.get('model');

            if (Vue.config.devtools == false && typeof fbq === 'function') { // check if you are on production-enviroment
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                window.dataLayer.push ({
                    'event' : 'configuration',
                    'model': model,
                    'edition': `${this.configuration.type.name} - ${this.configuration.version.name}`,
                    'page_path': window.location.href,
                    'country': `${country} - ${this.$root.languageCode}`,
                });
            }
        },

        /**
         * Create customer, complete order and handle payment.
         * 
         * @param {FormData} formData
         * @returns {Void}
         */
        handleDirectPayment(formData) {

            // Handle PDF export testing.
            if (this.testPdfExport) {

                // Build final data for SalesForce.
                formData.set('action', 'mg-business-logic/request-offer/complete-order');
                formData.append('orderId', this.testPdfExport);

                // Complete the order without validations or payments to trigger PDF export.
                axios
                    .post("/", formData, {responseType: 'blob'})
                    .then((response) => {

                        // Trigger download of the PDF file response.
                        this.submitting = false;
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.pdf'); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch(error => {
                        this.submitting = false;
                        console.log(error);
                    });
                return;
            }

            // Create customer.
            let customerData = new FormData();
            customerData.append('action', 'commerce/cart/update-cart');
            customerData.append(window.csrfTokenName, window.csrfTokenValue);
            customerData.append('shippingAddressSameAsBilling', 1);
            customerData.append('email', formData.get('emailAddress'));
            customerData.append('gatewayId', this.adyenGatewayId);
            customerData.append('billingAddress[firstName]', formData.get('firstName'));
            customerData.append('billingAddress[lastName]', formData.get('lastName'));
            customerData.append('billingAddress[businessName]', formData.get('company'));
            customerData.append('billingAddress[address1]', formData.get('streetName') +' '+ formData.get('houseNumber') + formData.get('houseNumberAddition'));
            customerData.append('billingAddress[city]', formData.get('city'));
            customerData.append('billingAddress[zipCode]', formData.get('postalCode'));
            customerData.append('billingAddress[phone]', formData.get('phone'));
            customerData.append('billingAddress[countryId]', this.getCommerceCountryId());
            customerData.append('fields[orderStore]', formData.get('store'));
            customerData.append('fields[orderConfiguration]', formData.get('configuration'));
            customerData.append('fields[orderHeardFrom]', formData.get('heardFrom'));
            customerData.append('fields[orderRemarks]', formData.get('remarks'));
            customerData.append('fields[orderUtmSource]', formData.get('utm_source'));
            customerData.append('fields[orderUtmMedium]', formData.get('utm_medium'));
            customerData.append('fields[orderUtmCampaign]', formData.get('utm_campaign'));
            customerData.append('fields[orderCountryNumber]', formData.get('siteId'));
            customerData.append('fields[orderStreetName]', formData.get('streetName'));
            customerData.append('fields[orderHouseNumber]', formData.get('houseNumber'));
            customerData.append('fields[orderHouseNumberAddition]', formData.get('houseNumberAddition'));
            customerData.append('fields[orderDistributorMail]', formData.get('distributorEmail'));
            axios
                .post("/", customerData)
                .then((response) => {

                    // Process payment.
                    let paymentData = new FormData();
                    paymentData.append('action', 'commerce/payments/pay');
                    paymentData.append(window.csrfTokenName, window.csrfTokenValue);
                    paymentData.append('cardname', this.creditCardValues.holderName);
                    paymentData.append('cardnumber', this.creditCardValues.cardnumber);
                    paymentData.append('expiryMonth', this.creditCardValues.expiryMonth);
                    paymentData.append('expiryYear', this.creditCardValues.expiryYear);
                    paymentData.append('cvv', this.creditCardValues.cvv);
                    paymentData.append('browserInfo', JSON.stringify(this.creditCardValues.browserInfo));
                    paymentData.append('street', formData.get('streetName'));
                    paymentData.append('housenumber', formData.get('houseNumber') + formData.get('houseNumberAddition'));
                    axios
                        .post("/", paymentData)
                        .then((response) => {

                            // Check bad payment result.
                            if (!response.data.success) {
                                this.errors.clear();
                                this.errors.record({'cardnumber': ['Credit Card payment failed.']});
                                this.submitting = false;
                                this.scrollToFirstError();
                                return;
                            }

                            // Handle redirect action.
                            if (response.data.cart.orderPaymentAction) {
                                const paymentAction = JSON.parse(response.data.cart.orderPaymentAction);
                                if (paymentAction.type != 'threeDS2Fingerprint') {
                                    this.submitting = false;
                                }
                                if (paymentAction.type == 'redirect') {
                                    this.trackFormSubmit(formData);
                                }
                                this.adyenCardFields.handleAction(paymentAction);
                                return;
                            }

                            // Build final data for SalesForce.
                            formData.set('action', 'mg-business-logic/request-offer/complete-order');
                            formData.append('orderId', response.data.cart.id);

                            // Complete the order!
                            axios
                                .post("/", formData)
                                .then((response) => {
                                    const result = response.data;

                                    // Handle form errors.
                                    if (result.data.errors) {
                                        this.submitting = false;
                                        this.errors.clear();
                                        this.errors.record(result.data.errors);
                                        this.scrollToFirstError();
                                        return;
                                    }

                                    // Track form submission.
                                    this.trackFormSubmit(formData);

                                    // Redirect to the thank you page.
                                    window.location = result.data.redirectUrl;
                                })
                                .catch(error => {
                                    this.submitting = false;
                                    console.log(error);
                                });
                        })
                        .catch(error => {
                            this.submitting = false;
                            console.log(error);
                        });
                })
                .catch(error => {
                    this.submitting = false;
                    console.log(error);
                });
        },

        /**
         * Returns country ID for commerce based on set locale.
         * 
         * @returns {Integer}
         */
        getCommerceCountryId() {
            const countryKey = this.locale.substr(-2);
            for (let country in this.commerceCountries) {
                if (this.commerceCountries[country].iso == countryKey) {
                    return this.commerceCountries[country].id;
                }
            }
        },

        /**
         * Trigger tracking of form submission.
         * 
         * @returns {Void}
         */
        trackFormSubmit(formData) {

            // Only track in production environment.
            if (Vue.config.devtools) {
                return;
            }

            // Track FB.
            if (typeof fbq === 'function') {
                fbq('track', 'Lead', { lead_type: 'Lead', country: this.siteHandle, color: this.configuration.color.key, edition: this.configuration.type.key, payment_type: this.configuration.priceIndication }); // NL
                fbq('track', 'CustomizeProduct', {content_type: formData.get('model'), content_ids: this.configuration.type.id, client_type: this.requestType == 'private' ? 'B2C' : 'B2B', brandstore_type: this.selectedBrandStore, color: this.configuration.color.key, country: this.siteHandle, edition: this.configuration.type.key, payment_type: this.configuration.price.key});
            }

            // Track floodlight.
            var axel = Math.random() + "";
            var a = axel * 10000000000000;
            let iframe = document.createElement('iframe');
            iframe.src = 'https://10256359.fls.doubleclick.net/activityi;src=10256359;type=mg_co0;cat=mg_le02;u1=' + this.siteHandle + ';u2=[language];u3=' + window.location.pathname + ';u4=[page_name];u8=[business];u9=zs-ev;u10=' + this.configuration.color.key + ';u11=' + this.configuration.type.key + ';dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?';
            iframe.width = 1;
            iframe.height = 1;
            iframe.frameborder = 0;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
        },

        /**
         * Open or close a custom dropdown element.
         * 
         * @param {ClickEvent|ChangeEvent} event 
         */
        toggleDropdown(event) {
            let element = event.currentTarget;
            while (element = element.parentElement) {
                if (element.classList.contains('dropdown')) {
                    element.classList.toggle('dropdown--active');
                }
            }
        },

        /**
         * Update heardFrom value when a selection takes place in its custom dropdown element.
         * 
         * @param {ChangeEvent} event 
         */
        setDropdownHeardFrom(event) {

            // Set text property to show value in template, set value in heardFrom property to send along with the form.
            this.dropdownHeardFrom = event.currentTarget.dataset.text;
            this.heardFrom = event.currentTarget.dataset.value;

            // Close the dropdown.
            this.toggleDropdown(event);
        },

        /**
         * Formats a given float as a price, with no decimals if there are none, or 2 if there are.
         * @param {Float} price 
         * @returns {String}
         */
        formatAsPrice(price) {

            // Set localization formatting.
            let countyPriceMapping = {
                localization: this.locale,
                showZeros: false,
            };
            if (this.$parent.countyPriceMapping[this.locale] != undefined) {
                countyPriceMapping = this.$parent.countyPriceMapping[this.locale];
            }

            // Format with 2 decimals if price as any decimals.
            if (price % 1 != 0 || countyPriceMapping.showZeros) {
                return price.toLocaleString(countyPriceMapping.localization, {minimumFractionDigits: 2, maximumFractionDigits: 2});
            }

            // Format with no decimal digits.
            return price.toLocaleString(countyPriceMapping.localization);
        },

        brandStoreSelected(store) {
            this.selectedBrandStore = store;
        }
    }
});
