import Vue from 'vue';
import Swiper from 'swiper';

Vue.component('reusable-slider-component', {
    props: {
        slides: {
            type: Array,
            default: () => [],
        },
        
        hasLoop: {
            type: Boolean,
            default: false,
        },

        hasAutoplay: {
            type: Boolean,
            default: false,
        },

        hasNavigation: {
            type: Boolean,
            default: false,
        },

        hasPagination: {
            type: Boolean,
            default: false,
        },

        paginationType: {
            type: String,
            default: 'bullets',
        }
    },
    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            swiper: null,
            swiperEl: null,
            navigationNext: null,
            navigationPrev: null,
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.swiperEl = this.$el.querySelector('.reusable-slider__container');
        this.navigationNext = this.$el.querySelector('.reusable-slider__navigation-next');
        this.navigationPrev = this.$el.querySelector('.reusable-slider__navigation-prev');

        this.initSwiper();
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initSwiper() {
            this.swiper = new Swiper(this.swiperEl, {
                loop: this.hasLoop,
                autoplay: this.hasAutoplay,
                navigation: () => {
                    if (!this.hasNavigation) {
                        return;
                    }

                    return {
                        nextEl: this.navigationNext,
                        prevEl: this.navigationPrev
                    };
                },
                pagination: () => {
                    if (!this.hasPagination) {
                        return;
                    }

                    return {
                        el: this.paginationEl,
                        type: this.paginationType
                    };
                }
            });
        }
    },
});
