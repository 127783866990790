import Vue from "vue";

Vue.component("configurator-edition-select", {
    props: {
        editions: {
            type: Array
        },
        initialEdition: {
            type: Object
        },
        noRedWithRed: {
            type: Boolean
        }
    },

    data() {
        return {
            selectionExpanded: false,
            selectedIndex: 0,
            edition: null,
            disclaimer: "",
            options: [],
            selectedOptions: [],
            showSardanaRed: true
        };
    },

    watch: {
        /**
         * Set edition when index is updated.
         *
         * @returns {Void}
         */
        selectedIndex(value) {
            this.edition = this.editions[value];
        },

        /**
         * Send event on edition change and update options object.
         *
         * @returns {Void}
         */
        edition(value) {
            // Emit event.
            EventBus.$emit("configurator-set-edition", value);

            try {
                this.trackEditionSelection();
            } catch (error) {
                console.error(error);
            }

            // Set options array for this edition and pre-select options.
            let options = value.field_commerceOptions;
            let selectedOptions = [];
            for (let optionIndex in options) {
                options[optionIndex].selected = parseInt(
                    options[optionIndex].field_selectedByDefault
                )
                    ? true
                    : false;
                options[optionIndex].priceAddition = 0;
                if (options[optionIndex].selected) {
                    selectedOptions.push(
                        options[optionIndex].field_optionHandle
                    );
                }
            }
            this.options = options;
            this.selectedOptions = selectedOptions;

            // Emit event for changed options.
            this.$nextTick(() => {
                EventBus.$emit(
                    "configurator-set-edition-options",
                    this.options
                );
            });
        }
    },

    mounted() {
        // Set initial edition and its index.
        this.edition = this.initialEdition;
        for (let editionIndex in this.editions) {
            if (
                this.editions[editionIndex].field_commerceHandle ==
                this.edition.field_commerceHandle
            ) {
                this.selectedIndex = editionIndex;
            }
        }

        // Update option price addition if a cost is emitted.
        EventBus.$on(
            "configurator-option-price-addition",
            (
                optionHandle,
                price,
                formattedPrice,
                shopPrice,
                showPriceImpact
            ) => {
                // Loop options to find the one with the given handle.
                for (let optionIndex in this.options) {
                    if (
                        this.options[optionIndex].field_optionHandle ==
                        optionHandle
                    ) {
                        // Update price in option object, then use Vue set to refresh.
                        this.options[optionIndex].priceAddition = "";
                        if (price && showPriceImpact) {
                            this.options[
                                optionIndex
                            ].priceAddition = formattedPrice;
                            this.options[
                                optionIndex
                            ].shopPriceAddition = shopPrice;
                        }
                        Vue.set(
                            this.options,
                            optionIndex,
                            this.options[optionIndex]
                        );
                    }
                }
            }
        );

        // Update edition disclaimer on payment option change.
        EventBus.$on("configurator-set-payment-option", paymentOption => {
            this.disclaimer = "";
            if (paymentOption.field_paymentOption_editionDisclaimer) {
                this.disclaimer =
                    paymentOption.field_paymentOption_editionDisclaimer;
            }
        });

        // Check red with red setting on color update.
        EventBus.$on("configurator-set-color", color => {
            // Don't show sardana red option if color is red and no red with red is enabled, and uncheck if checked.
            if (color.colorSlug == "phantom-red" && this.noRedWithRed) {
                this.showSardanaRed = false;
                for (let optionIndex in this.options) {
                    if (
                        this.options[optionIndex].field_optionHandle ==
                        "redinterior"
                    ) {
                        this.options[optionIndex].selected = false;
                        for (let selectedOption in this.selectedOptions) {
                            if (
                                this.selectedOptions[selectedOption] ==
                                "redinterior"
                            ) {
                                this.selectedOptions.splice(selectedOption, 1);
                            }
                        }
                        this.emitOptionChange(this.options[optionIndex]);
                    }
                }
                return;
            }

            // Show sardana red otherwise.
            this.showSardanaRed = true;
        });
    },

    methods: {
        /**
         * Update options selected property and trigger events if the select state of an option changed.
         *
         * @param {Object} option
         * @returns {Void}
         */
        onOptionSelectionChange(option) {
            option.selected = !option.selected;
            this.emitOptionChange(option);

            if (option.field_excludes.length > 0) {
                for (
                    let index = 0;
                    index < option.field_excludes.length;
                    index++
                ) {
                    const exclude = option.field_excludes[index];

                    for (let index = 0; index < this.options.length; index++) {
                        const option = this.options[index];

                        if (option.field_optionHandle === exclude) {
                            this.selectedOptions = this.selectedOptions.filter(
                                function(item) {
                                    return item !== exclude;
                                }
                            );
                            option.selected = false;
                            this.emitOptionChange(option);
                        }
                    }
                }
            }
        },

        /**
         * Trigger events if the select state of an option changed.
         *
         * @param {Object} option
         * @returns {Void}
         */
        emitOptionChange(option) {
            EventBus.$emit("configurator-set-edition-option", option);
            EventBus.$emit("configurator-set-edition-options", this.options);
        },

        /**
         * Track model selection if any tracker who wants it is loaded.
         *
         * @return {Void}
         */
        trackEditionSelection() {
            if (typeof p161 === "object") {
                let pixelId = "";
                if (this.$parent.siteHandle === "mgMotorsNetherlands") {
                    pixelId = "3161292";
                }
                if (this.$parent.siteHandle === "mgMotorsNorway") {
                    pixelId = "3161321";
                }
                if (pixelId !== "") {
                    p161.track(pixelId, {
                        customer_extra:
                            this.$parent.model +
                            "_" +
                            this.$parent.configuratorResult.color.key +
                            "_" +
                            this.edition.field_commerceHandle
                    });
                    if (typeof window.trackQueue === "function") {
                        window.trackQueue(p161);
                    }
                }
            }
        }
    }
});
