import Vue from 'vue';
import Glide from '@glidejs/glide/dist/glide.modular.esm';
import stickybits from "stickybits";

Vue.component("configurator-photo-slider", {
    props: {
        sliderClass: {
            value: String
        },
        model: {
            value: String
        },
    },

    data() {
        return {
            slider: null,
            stickyInstance: null,
            edition: '',
            color: '',
            exteriorOptions: '',
            interiorOptions: '',
        };
    },

    mounted() {

        // Initiate slider.
        this.initSlider();

        // Handle sticky.
        this.$nextTick(() => {
            this.handleSticky();
            EventBus.$on('window-resize', () => {
                this.handleSticky();
            });
        });

        // Update color when it changes and switch to first slide.
        EventBus.$on('configurator-set-color', (color) => {
            this.color = color.colorSlug;
            this.$nextTick(() => {
                this.initSlider('=0');
            });
        });

        // Update edition when it changes.
        EventBus.$on('configurator-set-edition', (edition) => {
            this.edition = edition.field_commerceHandle;
            this.$nextTick(() => {
                this.initSlider();
            });
        });

        // Set proper classes when the edition options have changed.
        EventBus.$on('configurator-set-edition-options', (options) => {
            this.onEditionOptionsChange(options);
        });

        // Slide to proper slide when an edition option has been selected.
        EventBus.$on('configurator-set-edition-option', (option) => {
            this.onEditionOptionChange(option);
        });
    },

    methods: {

        /**
         * (Re)initialize the slider with Glide.
         * 
         * @returns {Void}
         */
        initSlider(moveTo) {
            if (!this.$parent.hasInteriorImage) {
                moveTo = '=0';
            }

            let startIndex = 0;
            if (this.slider) {
                startIndex = this.slider.index;
                this.slider.destroy();
                this.slider = null;
            }

            this.slider = new Glide(this.sliderClass, {
                type: 'carousel',
                startAt: startIndex,
                gap: 0,
                peek: 0
            });

            if (moveTo) {
                this.slider.on('mount.after', () => {
                    this.slider.go(moveTo);
                });
            }

            this.slider.mount();
        },

        /**
         * Move to next slide.
         * 
         * @returns {Void}
         */
        nextSlide() {
            this.slider.go('>');
        },

        /**
         * Move to previous slide.
         * 
         * @returns {Void}
         */
        previousSlide() {
            this.slider.go('<');
        },

        /**
         * Enables or disables sticky slider depending on screen width.
         * 
         * @returns {Void}
         */
        handleSticky() {
            if (window.innerWidth >= 1024) {
                this.enableSticky();
                return;
            }
            this.disableSticky();
        },

        /**
         * Enables sticky slider if it hasn't been enabled yet.
         * 
         * @returns {Void}
         */
        enableSticky() {
            if (!this.stickyInstance) {
                this.stickyInstance = stickybits('.configurator__image-holder.sticky');
            }
        },

        /**
         * Clears and disables sticky slider if it is enabled.
         * 
         * @returns {Void}
         */
        disableSticky() {
            if (this.stickyInstance) {
                this.stickyInstance.cleanup();
                this.stickyInstance = null;
            }
        },

        /**
         * Set proper classes when the edition options have changed.
         * 
         * @param {Array} options Array holding all the available options with this edition.
         * @returns {Void}
         */
        onEditionOptionsChange(options) {

            // Collect classes in arrays.
            let exteriorOptions = [];
            let interiorOptions = [];

            // Loop options to update arrays.
            for (let option in options) {

                // Add to the proper class array if this option is selected.
                if (options[option].selected) {
                    if (options[option].field_moveToSliderImage == 'interior') {
                        interiorOptions.push(options[option].field_optionHandle);
                    }
                    if (options[option].field_moveToSliderImage == 'exterior') {
                        exteriorOptions.push(options[option].field_optionHandle);
                    }
                }
            }

            // Update class properties.
            this.exteriorOptions = exteriorOptions.join(' ');
            this.interiorOptions = interiorOptions.join(' ');
        },

        /**
         * Slide to proper slide when an edition option has been selected.
         * 
         * @param {Object} option The object of the option that has changed.
         * @returns {Void}
         */
        onEditionOptionChange(option) {
            if (!this.$parent.hasInteriorImage) {
                return;
            }

            let moveTo;

            // Slide to exterior slide if required by this option.
            if (option.field_moveToSliderImage == 'exterior') {
                moveTo = '=0';
            }

            // Slide to interior slide if required by this option.
            if (option.field_moveToSliderImage == 'interior') {
                moveTo = '=1';
            }

            // Reinitialize slider.
            this.$nextTick(() => {
                this.initSlider(moveTo);
            });
        },
    }
});
