/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import Vue from "vue";
import "lazysizes";
import debounce from "lodash/debounce";
import Swiper from "swiper";
import { gsap, Quad } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
var imagesLoaded = require("imagesloaded");

window.EventBus = new Vue();

window.mapsCallback = function () {
    EventBus.$emit("google-maps-ready");
};

require("./components/input-switch");
require("./components/configurator");
require("./components/configurator-model-select");
require("./components/configurator-technical-details");
require("./components/configurator-color-select");
require("./components/configurator-edition-select");
require("./components/configurator-version-select");
require("./components/configurator-delivery");
require("./components/configurator-payment-options");
require("./components/configurator-photo-slider");
require("./components/configurator-slider");
require("./components/configurator-price-indicator");
require("./components/configurator-form");
require("./components/configurator-dealer-search");
require("./components/tabslider");
require("./components/specs");
Vue.component("request-testdrive-component", () =>
    import("./components/request-testdrive-component")
);
require("./components/model-dropdown-component");
require("./components/contact-form-component");
require("./components/brand-stores-map-component");
require("./components/video-player");
require("./components/selling-points-component");
require("./components/cookie-message-component");
require("./components/faq-component");
require("./components/newsletter-form-component");
require("./components/language-links");
require("./components/career-component");
require("./components/fullwidth-slider");
require("./components/section-chapter");
require("./components/travel-distance");
require("./components/block-slider");
require("./components/youtube-slider");
require("./components/become-partner-component");
require("./components/campaign-component");
require("./components/range-simulator-component");
require("./components/battery-simulator-component");
require("./components/in-page-navigation");
require("./components/countdown-component");
require("./components/split-image-slider");
require("./components/thumb-slider");
require("./components/mobile-cta-bar");
require("./components/promo-form-component");
require("./components/teaser-form-component");
require("./components/dealer-search");
require("./components/tab-component");
require("./components/image-360-component");
require("./components/car-comparison-component");
require("./components/owner-manual-search-component");
require('./components/reusable-slider-component');

// eslint-disable-next-line no-unused-vars
window.MGApp = new Vue({
    el: "#app",
    delimiters: ["${", "}"],

    data() {
        return {
            showMenu: false,
            showSubmenu: false,
            showCountries: false,
            hasInteracted: false,
            handle: "default",
            languageCode: null,
            darkColorSchemeSwitch: false,
            countdownDeadlineReached: false,
            csrfTokenName: null,
            csrfTokenValue: null,
        };
    },

    watch: {
        showMenu: function (visible) {
            EventBus.$emit("navigation-state-changed", visible);
            if (!visible) {
                gsap.set(document.body, {
                    overflow: null
                });
                return;
            }

            gsap.set(document.body, {
                overflow: "hidden"
            });
        }
    },

    created() {
        if (!Modernizr.picture) {
            // Picture element HTML5 shiv
            document.createElement("picture");

            // Inject the polyfill
            const picturefill = document.createElement("script");
            picturefill.src =
                "https://cdn.rawgit.com/scottjehl/picturefill/3.0.2/dist/picturefill.min.js";
            picturefill.setAttribute("type", "text/javascript");
            picturefill.async = true;
            picturefill.defer = true;
            document.head.appendChild(picturefill);
        }
    },

    mounted() {
        window.addEventListener("resize", this.onWindowResize);

        this.languageCode = document.querySelector("html").getAttribute("lang");

        this.handle = document
            .querySelector("body")
            .getAttribute("data-handle");
        gsap.registerPlugin(ScrollTrigger);

        new imagesLoaded(this.$el, () => {
            this.initRevealTriggers();
        });

        const swiper = new Swiper(".teaser-slider", {
            slidesPerView: 1,
            spaceBetween: 0,
            effect: "fade",
            loop: true,
            centeredSlides: true,
            pagination: {
                el: ".teaser-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return (
                        '<span class="' +
                        className +
                        '"><span class="color-' +
                        (index + 1) +
                        '"></span></span></span>'
                    );
                }
            }
        });

        // Check interaction for FaceBook Pixel
        window.addEventListener("click", this.personHasInteracted.bind(this));
        window.addEventListener("scroll", this.personHasInteracted.bind(this));

        setTimeout(() => {
            if (this.hasInteracted) {
                if (Vue.config.devtools == false && typeof fbq === "function") {
                    // check if you are on production-enviroment
                    fbq("trackCustom", "QualifiedTraffic");

                    if (this.handle == "mgMotorsFrance") {
                        gtag("event", "conversion", {
                            allow_custom_scripts: true,
                            send_to: "DC-10256326/mg-autos/no_in0+unique"
                        }); //French
                    }

                    if (this.handle == "mgMotorsNorway") {
                        gtag("event", "conversion", {
                            allow_custom_scripts: true,
                            u1: this.handle,
                            u2: location.pathname,
                            send_to: "DC-10210913/retar0/no_re00+standard"
                        });
                        snaptr("track", "CUSTOM_EVENT_4");
                    }

                    if (this.handle == "mgMotorsNetherlands") {
                        this.insertTrackingImageLinkedin(
                            "https://px.ads.linkedin.com/collect/?pid=2629129&conversionId=2956258&fmt=gif"
                        );
                    }
                }
            }
        }, 10000);

        EventBus.$on("deadline-has-expired", value => {
            this.countdownDeadlineReached = value;
        });

        Vue.nextTick(() => {
            const scrollToEl = null;
            if (window.location.hash) {
                scrollToEl = document.querySelector(`${window.location.hash}`);
            }
            if (scrollToEl) {
                setTimeout(() => {
                    zenscroll.to(scrollToEl);
                }, 1000);
            }
        });

        /**
         * Get CSRF token and inject it into twig templates and window
         */
        axios.get('/actions/mg-motors-europe-module/site-controller/get-csrf').then((response => {
            const csrfTokenName = response.data.name;
            const csrfTokenValue = response.data.value;
            
            this.csrfTokenName = csrfTokenName;
            this.csrfTokenValue = csrfTokenValue;
            
            window.csrfTokenName = csrfTokenName;
            window.csrfTokenValue = csrfTokenValue;
        }));
    },

    updated() {
        ScrollTrigger.refresh();
    },

    methods: {
        trackDownloadBrochure(editionType, siteHandle, languageCode, pageUrl, modelName) {
            const strippedModelName = modelName.replace(/(<([^>]+)>)/gi, "");
            const country = (siteHandle = "default"
                ? "mgMotorsEurope"
                : siteHandle);

            if (Vue.config.devtools == false && typeof fbq === "function") {
                // check if you are on production-enviroment
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                dataLayer.push({
                    event: "Download brochure",
                    model: strippedModelName,
                    edition: editionType,
                    page_path: pageUrl,
                    country: `${country} - ${languageCode}`
                });

                gtag("event", "conversion", {
                    allow_custom_scripts: true,
                    u1: siteHandle,
                    u2: siteHandle,
                    u3: pageUrl,
                    u4: modelName,
                    send_to: "DC-10256359/mg_le0/mg_le000+standard"
                }); //nl

                if (this.handle == "mgMotorsFrance") {
                    // datalayer
                    dataLayer.push({
                        event: "interestAction",
                        eventCategory: "Interest",
                        eventAction: "Download brochure",
                        eventLabel: strippedModelName
                    });

                    var randomNumber = Math.floor(Math.random() * 10000) + 1; // used in france so a unique number is set to the transaction_id
                    gtag("event", "conversion", {
                        allow_custom_scripts: true,
                        u1: modelName,
                        send_to: "DC-10256326/mg-autos/fr_in00+unique"
                    });
                    gtag("event", "purchase", {
                        allow_custom_scripts: true,
                        value: "0",
                        transaction_id: randomNumber,
                        send_to: "DC-10256326/conv/fr_in000+transactions"
                    }); //French
                }

                if (this.handle == "mgMotorsNorway") {
                    gtag("event", "conversion", {
                        allow_custom_scripts: true,
                        u1: siteHandle,
                        u2: editionType,
                        u3: pageUrl,
                        u4: modelName,
                        send_to: "DC-10210913/lead40/no_in00+standard"
                    }); //norway
                    gtag("event", "conversion", {
                        allow_custom_scripts: true,
                        send_to: "DC-10210913/lead40/no_pd0+standard"
                    }); // Norway - NO_INI_Lead_PDF Download
                    snaptr("track", "CUSTOM_EVENT_1", {
                        item_category: modelName
                    });
                }

                if (this.handle == "mgMotorsSpain") {
                    var axel = Math.random() + "";
                    var a = axel * 10000000000000;
                    document.write(
                        '<iframe src="https://10256359.fls.doubleclick.net/activityi;src=10256359;type=mg_le0;cat=mg_le000;u1=' +
                        siteHandle +
                        ";u2=" +
                        siteHandle +
                        ";u3=" +
                        pageUrl +
                        ";u4=" +
                        pageUrl +
                        ";dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=" +
                        a +
                        '?" width="1" height="1" frameborder="0" style="display:none"></iframe>'
                    );
                }

                fbq("trackCustom", "BrochureDownload", {
                    Content_type: modelName
                }); //Globally (Original Dutch)

                if (this.handle == "mgMotorsNetherlands") {
                    this.insertTrackingImageLinkedin(
                        "https://px.ads.linkedin.com/collect/?pid=2629129&conversionId=2956306&fmt=gif"
                    );

                    // Trade deck
                    const tradeDeckCodes = {
                        "mg zs ev": "tocwpe",
                        "mg ehs": "o8vnc0k"
                    };

                    const model = strippedModelName.toLowerCase();

                    if (tradeDeckCodes[model]) {
                        const img = document.createElement("img");
                        img.style.height = "1";
                        img.style.width = "1";
                        img.style.borderStyle = "none";
                        img.src = `https://insight.adsrvr.org/track/pxl/?adv=teshga3&ct=0:${trackDeckCodes[model]}&fmt=3`;
                        document.body.appendChild(img);
                    }
                }

                if (
                    this.handle == "mgMotorsBelgiumFr" ||
                    this.handle == "mgMotorsBelgiumNl"
                ) {
                    _tfa.push({
                        notify: "event",
                        name: "Brochure",
                        id: 1384899
                    });
                }
            }
        },

        requestOfferFormTracking(url, model, siteHandle, languageCode, color, edition, engineType, paymentName) {
            const country = (siteHandle = "default" ? "mgMotorsEurope" : siteHandle);

            if (Vue.config.devtools == false && typeof fbq === "function") {
                // check if you are on production-enviroment
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                if (this.handle == "mgMotorsGermany") {
                    gtag("event", "conversion", {
                        allow_custom_scripts: true,
                        u1: url,
                        u2: model,
                        send_to: "DC-10844609/engag0/autok0+standard"
                    });
                }
            }
        },

        requestTestDriveTracking(url, model, siteHandle, languageCode, requestType) {
            // const country = siteHandle = 'default' ? 'mgMotorsEurope' : siteHandle;
            // if (Vue.config.devtools == false && typeof fbq === 'function') { // check if you are on production-enviroment
            //     if (!window.dataLayer) {
            //         window.dataLayer = window.dataLayer || [];
            //     }
            // }
        },

        requestContactFormTracking(url, referrer, siteHandle, languageCode) {
            const country = (siteHandle = 'default' ? 'mgMotorsEurope' : siteHandle);

            if (Vue.config.devtools == false && typeof fbq === "function") {
                // check if you are on production-enviroment
                // Daisycon pixel
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                window.dataLayer.push({
                    "campaign-id": "16022",
                    "transaction-id": "19705",
                    "product-name": "MG",
                    amount: "0",
                    revenue: "0",
                    "compensation-code": "Contact",
                    e1: `${referrer}`
                });
            }
        },

        requestNewsletterFormTracking(url, referrer, siteHandle, languageCode) {
            const country = (siteHandle = 'default' ? 'mgMotorsEurope' : siteHandle);

            if (Vue.config.devtools == false && typeof fbq === "function") {
                // check if you are on production-enviroment
                // Daisycon pixel
                if (!window.dataLayer) {
                    window.dataLayer = window.dataLayer || [];
                }

                window.dataLayer.push({
                    "campaign-id": "16022",
                    "transaction-id": "19705",
                    "product-name": "MG",
                    amount: "0",
                    revenue: "0",
                    "compensation-code": "Newsletter",
                    e1: `${referrer}`
                });
            }
        },

        insertTrackingImageLinkedin(source) {
            var img = document.createElement("img");
            img.height = "1";
            img.width = "1";
            img.style = "display:none;";
            img.src = source;
            var src = document.getElementById("app");
            src.appendChild(img);
        },

        insertTrackingImageAzerion(source) {
            var img = document.createElement("img");
            img.height = "1";
            img.width = "1";
            img.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
            img.style = "display:none;";
            img.src = source;
            var src = document.getElementById("app");
            src.appendChild(img);
        },

        insertTrackingImageShowHeroes(source) {
            var img = document.createElement("img");
            img.height = "1";
            img.width = "1";
            img.style = "display:none;";
            img.src = source;
            var src = document.getElementById("app");
            src.appendChild(img);
        },

        personHasInteracted() {
            if (this.hasInteracted) {
                window.removeEventListener("click", this.personHasInteracted);
                window.removeEventListener("scroll", this.personHasInteracted);
                return;
            }

            this.hasInteracted = true;
        },

        // This function will replace the input type file chosen file to display to the user.
        replaceInputValue(e) {
            const selectedFile = e.target.value.replace(`C:\\fakepath\\`, "");
            const textValueEl = e.target.parentNode.querySelector(
                'input[type="text"]'
            );
            textValueEl.value = selectedFile;
        },

        initRevealTriggers() {
            const elements = [
                ...document.querySelectorAll(".a-fade-up"),
                ...document.querySelectorAll(".a-fade-in"),
                ...document.querySelectorAll("[data-reveal]"),
                ...document.querySelectorAll(".opacity-blur"),
                ...document.querySelectorAll("[data-reveal-staggered]"),
                ...document.querySelectorAll(".zoomed-image")
            ];

            elements.forEach((element, index) => {
                const {
                    revealDelay,
                    revealManually,
                    revealPosition,
                    revealNumberCount,
                    revealStaggered
                } = element.dataset;

                if (revealManually === "") {
                    return;
                }

                let startPercentage = revealPosition || "88%";

                if (revealNumberCount) {
                    startPercentage = "75%";
                }

                gsap.timeline({
                    scrollTrigger: {
                        trigger: element,
                        start: `top ${startPercentage}`,
                        onEnter: () => {
                            if (revealNumberCount !== null) {
                                const numberCounts = element.querySelectorAll(
                                    ".number-count"
                                );

                                if (numberCounts) {
                                    for (let i = 0; i < numberCounts.length; i++) {
                                        let zero = { val: 0 };
                                        let num, split, decimals;
                                        const initWidth = numberCounts[i].offsetWidth;

                                        numberCounts[i].setAttribute('data-number', numberCounts[i].innerText);

                                        const makeNum = () => /^[0-9,.]*$/.test(numberCounts[i].dataset.number) ? parseFloat(numberCounts[i].dataset.number.replace(",", ".")) : parseInt(numberCounts[i].dataset.number);

                                        num = makeNum();

                                        // const spanNumberWidth = num.toString().replace(/[^0-9]/g, '') ? (window.innerWidth < 1440 ? 12 : 16) * num.toString().replace(/[^0-9]/g, '').length : null;
                                        // const spanPeriodWidth = num.toString().match(/\./g) ? (window.innerWidth < 1440 ? 5 : 7) * num.toString().match(/\./g).length : null;

                                        gsap.set(numberCounts[i], {
                                            width: initWidth
                                            // width: spanPeriodWidth ? spanNumberWidth + spanPeriodWidth : spanNumberWidth,
                                        });

                                        if (numberCounts[i].innerText.includes(".")) {
                                            split = (numberCounts[i].innerText + "").split(".");
                                        } else {
                                            split = (numberCounts[i].innerText + "").split(",");
                                        }

                                        decimals = split.length > 1 ? split[1].length : 0;

                                        gsap.to(zero, {
                                            val: num,
                                            duration: 2,
                                            ease: "power2.out",
                                            onUpdate: function () {
                                                if (zero) {
                                                    numberCounts[i].innerHTML = zero.val.toFixed(decimals);
                                                }
                                            }
                                        });
                                    }
                                }
                            }

                            if (revealStaggered === "") {
                                let parent = gsap.utils.selector(element);

                                gsap.to(parent(".staggered-zoomed-image"), {
                                    scale: 1,
                                    duration: 0.3
                                });

                                gsap.to(parent(".dual-block__content"), {
                                    x: 0,
                                    y: 0,
                                    duration: 0.3,
                                    delay: -0.6
                                });

                                gsap.to(parent(".staggered-fade"), {
                                    duration: 1.2,
                                    autoAlpha: 1,
                                    y: 0,
                                    stagger: 0.1,
                                    ease: "power3.out"
                                });

                                return;
                            }

                            if (!revealDelay) {
                                element.classList.add("--reveal");
                                return;
                            }

                            gsap.delayedCall(revealDelay, () => {
                                element.classList.add("--reveal");
                            });
                        }
                    }
                });
            });

            gsap.delayedCall(3, () => ScrollTrigger.refresh());
        },

        /**
         * Raised when the client window is resizing. Checks if the
         * application has switched to a mobile breakpoint and
         * act accordingly when a switch has indeed occurred.
         *
         * @return {Void}
         */
        onWindowResize: debounce(() => {
            EventBus.$emit("window-resize");
            ScrollTrigger.refresh();
        }, 150)
    },

    beforeDestroy() {
        window.addEventListener("resize", this.onWindowResize);
    }
});
