import Vue from 'vue';
Vue.component("car-comparison-component", {
    props: {
        allCarVariants: {
            type: Array
        },
        defaultVariants: {
            type: Array
        },
    },

    data() {
        return {
            compareCars: [],
            showAllSpecs: false,
            activeModelSelection: null,
        };
    },

    mounted() {
        if (this.defaultVariants[0]) {
            this.compareCars.push(this.getVariantWithParent(this.defaultVariants[0].parentId, parseInt(this.defaultVariants[0].id)));
        }

        if (this.defaultVariants[1]) {
            this.compareCars.push(this.getVariantWithParent(this.defaultVariants[1].parentId, parseInt(this.defaultVariants[1].id)));
        }
    },

    watch: {
        compareCars(value) {
            if (value.length >= 2) {
                // track comparison aanpassen er is geen product id meer
                this.trackComparison();
            }
        }
    },

    methods: {
        getVariantWithParent(parentId, variantId) {
            for (let i = 0; i < this.allCarVariants.length; i++) {
                if (this.allCarVariants[i].id == parentId) {
                    for (let j = 0; j < this.allCarVariants[i].variants.length; j++) {
                        if (parseInt(this.allCarVariants[i].variants[j].id) == variantId) {
                            const comparableCar = this.allCarVariants[i].variants[j];

                            comparableCar.modelTitle = this.allCarVariants[i].title;
                            comparableCar.removeSpace = this.allCarVariants[i].field_compareModelRemoveTitleSpace;
                            comparableCar.compareLabelText = this.allCarVariants[i].field_comparisonLabelText;
                            comparableCar.compareLabelColor = this.allCarVariants[i].field_comparisonLabelColor;

                            console.log(comparableCar);

                            return comparableCar;
                        }
                    }
                }
            }
            return null;
        },

        getProduct(productId) {
            for (let i = 0; i < this.allCarVariants.length; i++) {
                if (this.allCarVariants[i].id === productId) {
                    return this.allCarVariants[i];
                }
            }
            return null;
        },

        toggleSpecs() {
            this.showAllSpecs = !this.showAllSpecs;
        },

        toggleModelSelection(compareCarIndex) {
            if (this.activeModelSelection != null) {
                if (compareCarIndex == this.activeModelSelection) {
                    this.activeModelSelection = null;
                    return;
                }

                this.activeModelSelection = compareCarIndex;
            }

            this.activeModelSelection = compareCarIndex;
        },

        removeCar(compareCarIndex) {
            this.compareCars.splice(compareCarIndex, 1);
        },

        updateCar(compareCarIndex, variantId, parentId) {
            const newCar = this.getVariantWithParent(parentId, parseInt(variantId));
            const cars = this.compareCars;

            if (newCar) {
                cars[compareCarIndex] = newCar;
                this.compareCars = [...cars];
                this.activeModelSelection = null;
            }
        },

        addCar() {
            if(this.defaultVariants[2]) {
                this.compareCars.push(this.getVariantWithParent(this.defaultVariants[2].parentId, parseInt(this.defaultVariants[2].id)));
            } else {
                this.compareCars.push(this.getVariantWithParent(this.defaultVariants[0].parentId, parseInt(this.defaultVariants[0].id)));
            }
        },

        /**
         * Track model selection if any tracker who wants it is loaded.
         * 
         * @return {Void}
         */
        trackComparison() {
            if (typeof p161 === 'object') {
                let pixelId = '';
                if (this.$root.handle === 'mgMotorsNetherlands') {
                    pixelId = '3161303';
                }
                if (this.$root.handle === 'mgMotorsNorway') {
                    pixelId = '3161332';
                }

                let product = null;
                let comparison = '';
                product = this.getProduct(this.compareCars[0].productId);
                if (product) {
                    comparison = product.field_commerceHandle;
                }
                for (let i = 1; i < this.compareCars.length; i++) {
                    product = this.getProduct(this.compareCars[i].productId);
                    if (product) {
                        comparison += '_' + product.field_commerceHandle;
                    }
                }

                if (pixelId !== '') {
                    p161.track(pixelId, {customer_extra: comparison});
                    if (typeof window.trackQueue === 'function') {
                        window.trackQueue(p161);
                    }
                }
            }
        },
    }
});
