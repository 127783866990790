import Vue from "vue";
import _throttle from "lodash/throttle";

Vue.component("dealer-search", {
    props: {
        initialSiteHandle: {
            type: String
        },
        countryHandle: {
            type: String
        },
        stores: {
            value: Array
        },
        brandstoreResultAmount: {
            value: Object
        },
        passToParent: {
            value: Boolean,
            default: false
        }
    },

    data() {
        return {
            siteHandle: "",

            selectedBrandStore: "",
            searchBrandstore: "",
            zipCoords: null,
            searchQueryIsDirty: false,
            nearbyProximityStores: null,

            // Error vars for zip api request
            needMoreInput: false,
            apiRequestLoading: false,
            countryValue: {
                mgMotorsNetherlands: "NL",
                mgMotorsBelgiumNl: "BE",
                mgMotorsBelgiumFr: "BE",
                mgMotorsAustria: "AT",
                mgMotorsDanmark: "DK",
                mgMotorsGermany: "DE",
                mgMotorsSpain: "ES",
                mgMotorsFrance: "FR",
                mgMotorsIceland: "IS",
                mgMotorsItaly: "IT",
                mgMotorsLuxembourgFr: "LU",
                mgMotorsNorway: "NO",
                mgMotorsPortugal: "PT",
                mgMotorsSweden: "SE"
            }
        };
    },

    computed: {
        orderedStores: function() {
            return _orderBy(this.stores[this.siteHandle], "title");
        }
    },

    watch: {
        searchBrandstore() {
            if (this.searchBrandstore.length < 4) {
                this.needMoreInput = true;
                this.nearbyProximityStores = null;
                return;
            }

            if (this.selectedBrandStore) {
                if (this.selectedBrandStore.title === this.searchBrandstore) {
                    return;
                }
            }

            this.selectedBrandStore = null;
            this.needMoreInput = false;
            this.searchByZipGoogleApi();
        },

        countryHandle() {
            this.siteHandle = this.countryHandle;
        }
    },

    mounted() {
        // Set sitehandle and update it if it changes.
        this.siteHandle = this.initialSiteHandle;
        EventBus.$on("country-changed", siteHandle => {
            this.siteHandle = siteHandle;
        });

        this.injectGoogleMaps();
    },

    methods: {
        injectGoogleMaps() {
            var tag = document.createElement("script");
            tag.setAttribute("type", "text/javascript");
            tag.defer = true;
            tag.async = true;
            tag.setAttribute(
                "src",
                "//maps.google.com/maps/api/js?key=AIzaSyBXLteX7ErcVvjvFLHB1_TMCzq55wVb5Bg&libraries=places&callback=mapsCallback"
            );
            (
                document.getElementsByTagName("head")[0] ||
                document.documentElement
            ).appendChild(tag);
        },

        searchByZipGoogleApi: _throttle(function() {
            this.makeApiRequest();
        }, 1000),

        makeApiRequest() {
            this.apiRequestLoading = true;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
                {
                    componentRestrictions: {
                        country: this.countryValue[this.siteHandle]
                    },
                    address: encodeURI(this.searchBrandstore)
                },
                (results, status) => {
                    if (status === "OK") {
                        if (results[0]) {
                            s;
                            this.apiRequestLoading = false;
                            this.zipCoords = {
                                lat: results[0].geometry.location.lat(),
                                lng: results[0].geometry.location.lng()
                            };

                            if (
                                !this.zipCoords ||
                                this.stores[this.siteHandle].length < 1
                            ) {
                                return;
                            }

                            this.getNearbyBrandstores(
                                this.zipCoords,
                                this.stores[this.siteHandle]
                            );
                        }
                        return null;
                    } else {
                        if (status === "ZERO_RESULTS") {
                            this.apiRequestLoading = false;
                        }
                    }
                }
            );
        },

        getNearbyBrandstores(userLocation, brandstores) {
            const storesWithDistance = Object.values(brandstores).map(store => {
                const distance = this.getDistance(userLocation, store);
                return {
                    ...store,
                    distance
                };
            });

            this.nearbyProximityStores = storesWithDistance
                .sort((a, b) => {
                    if (a.distance < b.distance) {
                        return -1;
                    }

                    if (a.distance > b.distance) {
                        return 1;
                    }

                    return 0;
                })
                .slice(0, this.brandstoreResultAmount[this.siteHandle]);
        },

        getDistance(userLocation, brandstore) {
            // Bron: https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
            var radius = 6371.071; // Radius of the Earth in KM

            const radiusLat1 = userLocation.lat * (Math.PI / 180); // Convert degrees to radians
            const radiusLat2 = brandstore.lat * (Math.PI / 180); // Convert degrees to radians
            const differenceLat = radiusLat2 - radiusLat1; // Radian difference (latitudes)
            const differenceLng =
                (brandstore.lng - userLocation.lng) * (Math.PI / 180); // Radian difference (longitudes)

            const distance =
                2 *
                radius *
                Math.asin(
                    Math.sqrt(
                        Math.sin(differenceLat / 2) *
                            Math.sin(differenceLat / 2) +
                            Math.cos(radiusLat1) *
                                Math.cos(radiusLat2) *
                                Math.sin(differenceLng / 2) *
                                Math.sin(differenceLng / 2)
                    )
                );

            return distance;
        },

        replaceInputValue(e) {
            this.selectedBrandStore = this.stores[this.siteHandle][
                e.target.dataset.value
            ];
            this.searchBrandstore = this.selectedBrandStore.title;

            // Reset zipCoords to null
            this.zipCoords = null;

            if (!this.passToParent) {
                return;
            }

            this.$emit("selected-brandstore", this.selectedBrandStore);
        }
    }
});
