import Vue from 'vue';
import Errors from "../classes/errors";

Vue.component("campaign-component", {
    props: {
        slug: String,
        position: String
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            errors: new Errors(),
            campaignIndex: 0,
        };
    },

    computed: {
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        if (this.position === '1') {
            this.campaignIndex = 1;
        } else {
            this.campaignIndex = 0;
        }
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
    }
});
