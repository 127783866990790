import Vue from 'vue';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import zenscroll from 'zenscroll'

gsap.registerPlugin(ScrollTrigger);

Vue.component('section-chapter', {
    data() {
        return {
            chapterOpen: false,
            scrollToElement: null,
        };
    },

    methods: {
        toggleChapter(e) {
            this.chapterOpen = !this.chapterOpen;

            // reset scrolltrigger when section opens/closes
            ScrollTrigger.refresh();

            if (this.chapterOpen) {
                gsap.fromTo(this.$el.querySelector('.section-chapter'), { height: 0 }, { height: 'auto', duration: .6 })
                this.scrollToElement = e.currentTarget
                zenscroll.to(this.$el.querySelector('.section-chapter'));

                // datalayer 
                if (this.$root.handle == 'mgMotorsFrance') {
                    dataLayer.push({
                        'event': 'contentClic',
                        'eventCategory': 'Showroom Content',
                        'eventAction': e.currentTarget.dataset.section,
                        'eventLabel': e.currentTarget.dataset.model
                    });
                }
            } else {
                gsap.fromTo(this.$el.querySelector('.section-chapter'), { height: 'auto' }, { height: 0, duration: .6, delay: .4 })
                zenscroll.center(this.scrollToElement)
                if (Vue.config.devtools == false && typeof fbq === 'function') { // check if you are on production-enviroment
                    if (this.$root.handle == 'mgMotorsFrance') {
                        gtag('event', 'conversion', { 'allow_custom_scripts': true, 'send_to': 'DC-10256326/mg-autos/fr_in002+unique' }); //french
                    }

                    if (this.$root.handle == 'mgMotorsNorway') {
                        gtag('event', 'conversion', { 'allow_custom_scripts': true, 'send_to': 'DC-10210913/retar0/no_re001+standard' }); //norway
                    }
                }
            }
        },
    },
});
