import Vue from 'vue';

Vue.component('range-simulator-component', {
    props: {
        values: {
            type: Object
        },
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            aircoValue: 'on',
            airco: 0,
            temperatures: [-10, -7, -5, 0, 5, 10, 15, 20, 25, 30],
            currentTemperature: null,
            currentTempIndex: 7,
            speeds: [30, 50, 80, 100, 120],
            currentSpeed: null,
            currentSpeedIndex: 0,
            rangeResult: null,
        };
    },

    watch: {
        currentTempIndex:  function (newVal, oldVal) {
            this.currentTemperature = this.temperatures[newVal]
            this.rangeResult = this.values[this.aircoValue].speed[this.currentSpeed][this.currentTemperature]
        },
        currentSpeedIndex:  function (newVal, oldVal) {
            this.currentSpeed = this.speeds[newVal]
            this.rangeResult = this.values[this.aircoValue].speed[this.currentSpeed][this.currentTemperature]
        },
        aircoValue:  function (newVal, oldVal) {
            this.rangeResult = this.values[this.aircoValue].speed[this.currentSpeed][this.currentTemperature]
        },
    },

    computed: {
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        this.currentTemperature = this.temperatures[this.currentTempIndex]
        this.currentSpeed = this.speeds[this.currentSpeedIndex]

        this.rangeResult = this.values[this.aircoValue].speed[this.currentSpeed][this.currentTemperature]
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        reduceTemp() {
            if(this.currentTempIndex > 0) {
                this.currentTempIndex -= 1
            }
        },
        addTemp() {
            if(this.currentTempIndex < this.temperatures.length - 1) {
                this.currentTempIndex += 1
            }
        },
        reduceSpeed() {
            if(this.currentSpeedIndex > 0) {
                this.currentSpeedIndex -= 1
            }
        },
        addSpeed() {
            if(this.currentSpeedIndex < this.speeds.length - 1) {
                this.currentSpeedIndex += 1
            }
        }
    },
});
