import Vue from 'vue';
import noUiSlider from 'nouislider';
import 'nouislider/distribute/nouislider.css';
import gsap from 'gsap';
import Swiper from 'swiper';

Vue.component('battery-simulator-component', {
    props: {
        values: {
            type: Object
        },
    },

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            rangeSlider: null,
            rangeValues: null,
            chargeTime2kw: null,
            chargeTime3kw: null,
            chargeTime6kw: null,
            chargeTime50kw: null,
            chargeTime100kw: null,
        };
    },

    watch: {
        rangeValues: function (newVal, oldVal) {

            if(newVal[0] > '70%') {
                this.rangeSlider.set([70, newVal[1]]);
            } else {
                this.chargeTime2kw = this.round(this.values['2kw'].percentage[newVal[1]] - this.values['2kw'].percentage[newVal[0]], 1)
                this.chargeTime3kw = this.round(this.values['3kw'].percentage[newVal[1]] - this.values['3kw'].percentage[newVal[0]], 1)
                this.chargeTime6kw = this.round(this.values['6kw'].percentage[newVal[1]] - this.values['6kw'].percentage[newVal[0]], 1)
                this.chargeTime50kw = this.round(this.values['50kw'].percentage[newVal[1]] - this.values['50kw'].percentage[newVal[0]], 1)
                this.chargeTime100kw = this.round(this.values['100kw'].percentage[newVal[1]] - this.values['100kw'].percentage[newVal[0]], 1)
    
                this.setTimeBlocks()
            }

        }
    },

    computed: {
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {

        this.initSlider();
        this.initMobileSwiper();

        this.chargeTime2kw = this.round(this.values['2kw'].percentage[this.rangeValues[1]] - this.values['2kw'].percentage[this.rangeValues[0]], 1)
        this.chargeTime3kw = this.round(this.values['3kw'].percentage[this.rangeValues[1]] - this.values['2kw'].percentage[this.rangeValues[0]], 1)
        this.chargeTime6kw = this.round(this.values['6kw'].percentage[this.rangeValues[1]] - this.values['6kw'].percentage[this.rangeValues[0]], 1)
        this.chargeTime50kw = this.round(this.values['50kw'].percentage[this.rangeValues[1]] - this.values['50kw'].percentage[this.rangeValues[0]], 1)
        this.chargeTime100kw = this.round(this.values['100kw'].percentage[this.rangeValues[1]] - this.values['100kw'].percentage[this.rangeValues[0]], 1)

        this.setTimeBlocks()

    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        initSlider() {
            var slider = this.$el.querySelector('.battery-simulator__module__battery-slider .battery-slider');

            this.rangeSlider = noUiSlider.create(slider, {
                // Create two timestamps to define a range.
                range: {
                    'min': 0,
                    '15%': 15,
                    '30%': 30,
                    '50%': 50,
                    '70%': 70,
                    '80%': 80,
                    '90%': 90,
                    '95%': 95,
                    'max': 100
                },
                format: {
                    // 'to' the formatted value. Receives a number.
                    to: function (value) {
                        return value + '%';
                    },
                    // 'from' the formatted value.
                    // Receives a string, should return a number.
                    from: function (value) {
                        return Number(value);
                    }
                },
                snap: true,
                tooltips: true,
                connect: true,
                // Two more timestamps indicate the handle starting positions.
                start: [0, 80],
    
            });
    
            this.rangeValues = this.rangeSlider.get()
    
            this.rangeSlider.on('update', (values, handle) => {
                this.rangeValues = values;
            });
        },

        setTimeBlocks() {

            const chargeBlock2kw = this.$el.querySelector('.battery-simulator__ac-charging__statistic-block--2kw')
            const chargeBlock3kw = this.$el.querySelector('.battery-simulator__ac-charging__statistic-block--3kw')
            const chargeBlock6kw = this.$el.querySelector('.battery-simulator__ac-charging__statistic-block--6kw')
            const chargeBlock50kw = this.$el.querySelector('.battery-simulator__dc-charging__statistic-block--50kw')
            const chargeBlock100kw = this.$el.querySelector('.battery-simulator__dc-charging__statistic-block--100kw')

            const chargeTime50kwHours = this.chargeTime50kw/60 // turn minutes in hours to get right value for the stat blocks
            const chargeTime100kwHours = this.chargeTime100kw/60 // turn minutes in hours to get right value for the stat blocks

            gsap.to(chargeBlock2kw, { width: this.chargeTime2kw*15, duration: .6 })
            gsap.to(chargeBlock3kw, { width: this.chargeTime3kw*15, duration: .6 })
            gsap.to(chargeBlock6kw, { width: this.chargeTime6kw*15, duration: .6 })
            gsap.to(chargeBlock50kw, { width: chargeTime50kwHours*15, duration: .6 })
            gsap.to(chargeBlock100kw, { width: chargeTime100kwHours*15, duration: .6 })
        },

        round(value, precision) {
            var multiplier = Math.pow(10, precision || 0);
            return Math.round(value * multiplier) / multiplier;
        },
        initMobileSwiper() {
            const swiperContainer = this.$el.querySelector('.battery-simulator__mobile-swiper-container')
            var mySwiper = new Swiper(swiperContainer, {
                speed: 400,
                slidesPerView: 1.3,
                spaceBetween: 10,
                centeredSlides: true,
            });
        },

    },
});
