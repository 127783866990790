import Vue from 'vue';
import Glide from '@glidejs/glide/dist/glide.modular.esm';

Vue.component("configurator-slider", {
    props: {
        sliderClass: {
            value: String
        },
        model: {
            value: String
        },
    },

    data() {
        return {
            slider: null,
            edition: '',
            color: '',
            exteriorOptions: '',
            interiorOptions: '',
        };
    },

    mounted() {

        // Initiate slider.
        this.initSlider();

        // Update color when it changes and switch to first slide.
        EventBus.$on('configurator-set-color', (color) => {
            this.color = color.colorSlug;
            this.slider.go('=0');
        });

        // Update edition when it changes.
        EventBus.$on('configurator-set-edition', (edition) => {
            this.edition = edition.field_commerceHandle;
        });

        // Set proper classes when the edition options have changed.
        EventBus.$on('configurator-set-edition-options', (options) => {
            this.onEditionOptionsChange(options);
        });

        // Slide to proper slide when an edition option has been selected.
        EventBus.$on('configurator-set-edition-option', (option) => {
            this.onEditionOptionChange(option);
        });
    },

    methods: {

        /**
         * (Re)initialize the slider with Glide.
         * 
         * @returns {Void}
         */
        initSlider() {
            if (this.slider) {
                this.slider.destroy();
                this.slider = null;
            }

            this.slider = new Glide(this.sliderClass, {
                type: 'carousel',
                startAt: 0,
                gap: 0,
                peek: 0
            });

            this.slider.mount();
        },

        /**
         * Move to next slide.
         * 
         * @returns {Void}
         */
        nextSlide() {
            this.slider.go('>');
        },

        /**
         * Move to previous slide.
         * 
         * @returns {Void}
         */
        previousSlide() {
            this.slider.go('<');
        },

        /**
         * Set proper classes when the edition options have changed.
         * 
         * @param {Array} options Array holding all the available options with this edition.
         * @returns {Void}
         */
        onEditionOptionsChange(options) {

            // Collect classes in arrays.
            let exteriorOptions = [];
            let interiorOptions = [];

            // Loop options to update arrays.
            for (let option in options) {

                // Add to the proper class array if this option is selected.
                if (options[option].selected) {
                    if (options[option].field_moveToSliderImage == 'interior') {
                        interiorOptions.push(options[option].field_optionHandle);
                    }
                    if (options[option].field_moveToSliderImage == 'exterior') {
                        exteriorOptions.push(options[option].field_optionHandle);
                    }
                }
            }

            // Update class properties.
            this.exteriorOptions = exteriorOptions.join(' ');
            this.interiorOptions = interiorOptions.join(' ');
        },

        /**
         * Slide to proper slide when an edition option has been selected.
         * 
         * @param {Object} option The object of the option that has changed.
         * @returns {Void}
         */
        onEditionOptionChange(option) {

            // Slide to exterior slide if required by this option.
            if (option.field_moveToSliderImage == 'exterior') {
                this.slider.go('=0');
            }

            // Slide to interior slide if required by this option.
            if (option.field_moveToSliderImage == 'interior') {
                this.slider.go('=1');
            }
        },
    }
});
