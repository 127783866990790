import Vue from 'vue';

Vue.component('language-links', {
    methods: {
        /**
         * Go to given URL with Geomate override cookie set.
         * 
         * @param {String} url 
         * @param {String} siteName 
         */
        switchLanguage(url, siteName) {

            // Set cookie expiration date 7 days in the future (= geomate default)
            let expire = new Date();
            expire.setDate(expire.getDate() + 7);

            // Set override cookie
            document.cookie = 'GeoMateRedirectOverride='+ siteName +'; expires='+ expire.toUTCString() +'; path=/';

            // Move to URL
            document.location.href = url;
        }
    },
});
