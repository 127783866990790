import Vue from 'vue';

Vue.component("configurator-version-select", {

    data() {
        return {
            selectionExpanded: false,
            selectedIndex: 0,
            version: null,
            edition: null,
        };
    },

    watch: {

        /**
         * Set edition when index is updated.
         * 
         * @returns {Void}
         */
        selectedIndex(value) {
            this.updateVersion();
        },

        /**
         * Send event on version change.
         * 
         * @returns {Void}
         */
        version(value) {

            // Emit event.
            EventBus.$emit('configurator-set-version', value);
            // this.trackVersionSelection();
        },
    },

    mounted() {

        // Update edition disclaimer on payment option change.
        EventBus.$on('configurator-set-edition', (edition) => {
            this.edition = edition;
            this.resetVersion();
        });
    },

    methods: {

        /**
         * Resets version to the first one.
         * 
         * @returns {Void}
         */
        resetVersion() {
            if(this.selectedIndex !== 0) {
                this.selectedIndex = 0;
                return;
            }
            this.updateVersion();
        },

        /**
         * Sets current selected version from version index or null if this edition doesn't have versions.
         * @returns {Void}
         */
        updateVersion() {
            if (this.edition.field_commerceVersions.length) {
               this.version = this.edition.field_commerceVersions[this.selectedIndex];
               return;
            }
            this.version = null;
        },

        /**
         * Track version selection if any tracker who wants it is loaded.
         * 
         * @return {Void}
         */
        // trackVersionSelection() {
        //     if (typeof p161 === 'object') {
        //         let pixelId = '';
        //         if (this.$parent.siteHandle === 'mgMotorsNetherlands') {
        //             pixelId = '3161292';
        //         }
        //         if (this.$parent.siteHandle === 'mgMotorsNorway') {
        //             pixelId = '3161321';
        //         }
        //         if (pixelId !== '') {
        //             p161.track(pixelId, {customer_extra: this.$parent.model + '_' + this.$parent.configuratorResult.color.key + '_' + this.edition.field_commerceHandle});
        //             if (typeof window.trackQueue === 'function') {
        //                 window.trackQueue(p161);
        //             }
        //         }
        //     }
        // },
    }
});
