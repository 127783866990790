import Vue from 'vue';

Vue.component('faq-component', {

    /**
     * Vue.js instance reactive data variables.
     *
     * @return {Object}
     */
    data() {
        return {
            
        };
    },

    /**
     * Called when the Vue.js instance is mounted and
     * ready for use.
     *
     * @return {void}
     */
    mounted() {
        
    },

    /**
     * Vue.js instance methods.
     *
     * @type {Object}
     */
    methods: {
        /**
         * Toggles the faq active state
         *
         * @param {Event} event
         */
        toggleFaq(event) {
            const faqItem = event.currentTarget.parentElement;
            const faqAnswer = faqItem.querySelector('.faq__answer');

            if (faqAnswer.classList.contains('active')) {
                faqItem.classList.remove('active');

                faqAnswer.classList.remove('active');
                // TweenMax.to(faqAnswer, 0.3, {
                //     className: '-=active',
                //     ease: Quad.easeOut,
                // });
            } else {
                faqItem.classList.add('active');
                faqAnswer.classList.add('active');

                // TweenMax.to(faqAnswer, 0.3, {
                //     className: '+=active',
                //     ease: Quad.easeOut,
                // });
            }
        },
    },
});
