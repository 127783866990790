import Vue from 'vue';

Vue.component("configurator-model-select", {
    data() {
        return {
            selectionExpanded: false,
        };
    },

    methods: {

        /**
         * Track model selection if any tracker who wants it is loaded.
         * 
         * @param {String} model
         * @return {Void}
         */
        trackModelSelection(model) {
            if (typeof p161 === 'object') {
                let pixelId = '';
                if (this.$parent.siteHandle === 'mgMotorsNetherlands') {
                    pixelId = '3161288';
                }
                if (this.$parent.siteHandle === 'mgMotorsNorway') {
                    pixelId = '3161319';
                }
                if (pixelId !== '') {
                    p161.track(pixelId, {customer_extra: model});
                    if (typeof window.trackQueue === 'function') {
                        window.trackQueue(p161);
                    }
                }
            }
        },
    },
});
