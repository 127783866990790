import Vue from 'vue';

Vue.component("configurator-color-select", {
    props: {
        colors: {
            type: Array
        },
        initialColor: {
            type: Object
        },
    },

    data() {
        return {
            selectionExpanded: false,
            availableColors: [],
            color: null,
            colorExtraCost: '',
            colorExtraCostNotification: '',
            editionHandle: '',
        };
    },

    watch: {

        /**
         * Send event on color change.
         * 
         * @returns {Void}
         */
        color(value) {
            EventBus.$emit('configurator-set-color', value);
            this.trackColorSelection();
        },
    },

    mounted() {

        // Set initial color.
        this.availableColors = this.colors;
        this.color = this.initialColor;

        // Update color extra cost if a cost is emitted.
        EventBus.$on('configurator-color-extra-cost', (extraCost, extraCostFormatted, showExtraCost, notification) => {
            this.colorExtraCost = '';
            this.colorExtraCostNotification = '';
            
            if (extraCost > 0 && showExtraCost) {
                this.colorExtraCost = extraCostFormatted;

                if (notification) {
                    this.colorExtraCostNotification = notification;
                }
            }
        });

        // Update editionHandle if edition changes.
        EventBus.$on('configurator-set-edition', (edition) => {
            this.editionHandle = edition.field_commerceHandle;
            this.updateAvailableColors();
        });
    },

    methods: {

        updateAvailableColors() {
            // Loop colors to check which are available.
            let reset = false;
            this.availableColors = [];
            for (let i = 0; i < this.colors.length; i++) {
                if (_.indexOf(this.colors[i].outOfStock, this.editionHandle) === -1) {
                    this.availableColors.push(this.colors[i]);
                    continue;
                }
                if (this.color.colorSlug === this.colors[i].colorSlug) {
                    reset = true;
                }
            }

            if (reset) {
                this.color = this.availableColors[0];
            }
        },

        /**
         * Track model selection if any tracker who wants it is loaded.
         * 
         * @return {Void}
         */
        trackColorSelection() {
            if (typeof p161 === 'object') {
                let pixelId = '';
                if (this.$parent.siteHandle === 'mgMotorsNetherlands') {
                    pixelId = '3161291';
                }
                if (this.$parent.siteHandle === 'mgMotorsNorway') {
                    pixelId = '3161320';
                }
                if (pixelId !== '') {
                    p161.track(pixelId, {customer_extra: this.$parent.model + '_' + this.color.colorSlug});
                    if (typeof window.trackQueue === 'function') {
                        window.trackQueue(p161);
                    }
                }
            }
        },
    },
});
